import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { errorRenderer } from "../../../utils/utility";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import teamService from "../../../services/manageTeams";
import { usePrevious } from "./../../../hooks/userPrevious";
import isEmpty from "lodash/isEmpty";
import firebase from '../../../config'
import {
  addNotification,
  notificationStates,
} from "../../../components/Notification";
import moment from "moment-timezone";

export default function MemberDialogForm({
  toggleMemberDialog,
  memberDialogForm,
  formType,
  memberDetails,
}) {
  const database = firebase.firestore();

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Member name is required"),
    selectedTeam: Yup.string().required("Select at least one team"),
  });

  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    control,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(FormSchema) });
  const dispatch = useDispatch();

  const teamState = useSelector((state) => ({ ...state.team }));
  let prevTeams = usePrevious(teamState?.teams);
  let currentTeams = teamState?.teams;
  let rawData = currentTeams.data.filter(
    (item, index) =>
      item.Role == "Manager" || item.Role == "Admin" || item.Role == "Head"
  );

  let teamData = memberDetails.teams.map((team) => ({
    label: team.teamName,
    teamId: team.teamId,
    role: team.role
  }))

  const authState = useSelector((state) => ({ ...state.auth }));
  let userProfile = authState?.profile;
  const [rates, setRates] = useState()
  let [teamOptions, setTeamOps] = useState([]);
  let [requesting, setRequesting] = useState(false);
  let [formFields, setFormFields] = useState({
    name: "",
    email: "",
    selectedTeam: {},
    role: "",
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isTeamSelected, setIsTeamSelected] = useState(false)

  const [timezones, setTimezones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone(userTimeZone);

    // Get all timezones using moment-timezone
    const allTimezones = moment.tz.names().map((tz) => ({ label: tz, value: tz }));
    setTimezones(allTimezones);
  }, []);

  const handleTimeZoneChange = (e) => {
    setSelectedTimeZone(e.value);
  };

  const handleFormFieldChanges = async (field, value) => {
    setFormFields({})
    let temp = { ...formFields };
    const role = value.role
    const teamId = value.teamId

    const teamData = await database.collection("teamDatabase").doc(teamId).get()
    const document = teamData.data()
    const teamMembers = document.memberDetails;
    const objectWithKeySomething = teamMembers[memberDetails?.userUID];
    const rate = objectWithKeySomething.rates ? objectWithKeySomething.rates : 0
    const timeZone = objectWithKeySomething.timeZone ? objectWithKeySomething.timeZone : 'Select TimeZone'

    temp[field] = value;
    temp["role"] = role;
    setRates(Number(rate));
    setValue(field, value);
    setFormFields({ ...temp });
    clearErrors(field);
    setIsTeamSelected(true);
    setSelectedTimeZone(timeZone);
  }

  useEffect(() => {
    if (prevTeams !== currentTeams) {
      setTeamOps([...teamData])
    }
  }, [memberDetails]);

  function registerFields() {
    register("name");
    setValue("name", memberDetails.userName);
    register("email");
    setValue("email", memberDetails.userEmail);
    register("selectedTeam");
    setValue("selectedTeam", memberDetails.teams[0].teamId);
    register("role");
    setValue("role", memberDetails.teams[0].role);
  }

  useEffect(() => {
    if (formType == "edit" && memberDetails) {
      if (!isEmpty(memberDetails)) {
        registerFields();
        let temp = {};
        temp["name"] = memberDetails.userName;
        temp["email"] = memberDetails.userEmail;
        temp["selectedTeam"] = memberDetails.teams[0].teamId;
        temp["role"] = memberDetails.teams[0].role;
        setFormFields({ ...temp });
      } else {
        resettingForm();
      }
    }
  }, [formType, memberDetails]);

  function haveValuesChanged() {
    let res = {
      changes: false,
      sameTeam: true,
      sameRole: true,
    };
    let { email, name, role, selectedTeam } = formFields;
    let { teams, userName, userEmail } = memberDetails;
    if (email != userEmail) res["changes"] = true;
    else if (name != userName) res["changes"] = true;
    else if (selectedTeam != teams[0].teamId) {
      res["sameTeam"] = false;
      res["changes"] = true;
    } else if (role != teams[0].role) {
      res["sameRole"] = false;
      res["changes"] = true;
    }
    return { ...res };
  }

  const handleMemberFormSubmit = async (data) => {
    try {
      setRequesting(true);
      let { changes, sameTeam, sameRole } = haveValuesChanged();

      if (changes) {
        let teamDetails = teamOptions.filter(
          (to) => to.value == formFields.selectedTeam
        )[0];
        let reqPayload = {
          ...formFields,
          userUID: memberDetails.userUID,
          teamDetails,
        };
        if ((!sameTeam && sameRole) || (!sameTeam && !sameRole)) {
          reqPayload["oldTeam"] = memberDetails.teams[0].teamId;
        }
        let response = await teamService.updateMemberDetails({
          ...reqPayload,
          companyId: userProfile?.activeCompany?.companyId,
        });
        handleResponse(response);
      } else {
        resettingForm();
        toggleMemberDialog(false);
      }
    } catch (e) {
      setRequesting(false);
    }
  };

  function handleResponse(response) {
    if (response.success) {
      dispatch({ type: "teams/GET_MEMBERS", payload: { teams: [...rawData] } });
      toggleMemberDialog(false);
      resettingForm();
      addNotification({
        message: response.data,
        level: notificationStates.success,
      });
    } else {
      addNotification({
        message: response.data,
        level: notificationStates.error,
      });
    }
  }

  useEffect(() => {
    return () => {
      resettingForm();
    };
  }, []);

  function resettingForm() {
    setRequesting(false);
    reset();
    setFormFields({ name: "", email: "", selectedTeam: {}, role: "" });
  }
  const handleChange = (e) => {
    const input = e.target.value;
    if (/[a-zA-Z]/.test(input)) {
      setErrorMessage('Please enter only numeric characters.');
    } else {
      setErrorMessage('');
      setRates(Number(input));
    }
  };
  const handleSave = () => {
    const teamId = memberDetails?.teams.filter((item) => {
      return item.teamName == formFields?.selectedTeam.label
    })
    const teamRef = database.collection("teamDatabase").doc(teamId[0]?.teamId);
    let batch = database.batch();
    batch.update(teamRef, {
      [`memberDetails.${memberDetails.userUID}`]: {
        userEmailid: memberDetails.userEmail,
        userName: memberDetails.userName,
        userType: memberDetails.role,
        rates: rates,
        timeZone: selectedTimeZone,
      },
    });
    batch.commit().then(() => {
      console.log("Batch write successful!");
      toggleMemberDialog(false)
    }).catch(error => {
      console.error("Error writing batch: ", error);
    })
  }
  return (
    <Dialog
      blockScroll
      draggable={false}
      header={"Edit member details"}
      contentClassName="rounded-bottom"
      visible={memberDialogForm}
      onHide={() => toggleMemberDialog(false)}
      style={{ width: "360px" }}
    >
      <div>
        <form onSubmit={handleSubmit(handleMemberFormSubmit)}>
          <div className="p-field my-3">
            <label htmlFor="name" className="block text-black">
              Name
            </label>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id="name"
                  {...field}
                  value={formFields?.name}
                  onChange={(e) => {
                    handleFormFieldChanges("name", e.target.value);
                  }}
                  placeholder="Name"
                  className={classNames(
                    { "p-invalid": errors?.name },
                    "p-inputtext-sm w-100"
                  )}
                />
              )}
            />
            {errorRenderer(errors, "name")}
          </div>
          <div className="p-field my-3">
            <label htmlFor="email" className="block text-black">
              Email
            </label>
            <InputText
              style={{ background: "aliceblue" }}
              id="email"
              className="p-inputtext-sm w-100"
              value={formFields?.email}
              disabled
              readOnly
              placeholder="email"
            />
          </div>
          <div className="p-field my-3">
            <label htmlFor="team" className="block text-black">
              Team
            </label>
            <Dropdown
              value={formFields?.selectedTeam}
              options={teamOptions}
              onChange={(e) => handleFormFieldChanges("selectedTeam", e.value)}
              placeholder="Select a team"
              className={classNames(
                { "p-invalid": errors?.selectedTeam },
                "w-100"
              )}
            />
            {errorRenderer(errors, "team")}
          </div>
          {isTeamSelected ?
            <>
              <div className="p-field my-3">
                <label htmlFor="role" className="block text-black">
                  Access Level
                </label>
                <InputText
                  style={{ background: "aliceblue" }}
                  id="access_level"
                  className="p-inputtext-sm w-100"
                  value={formFields?.role}
                  disabled
                  readOnly
                />
              </div>
              <div className="p-field my-3">
                <label htmlFor="role" className="block text-black">
                  Rates
                </label>
                <InputText
                  id="rates"
                  className="p-inputtext-sm w-100"
                  value={formFields?.rates ? formFields?.rates : rates}
                  onChange={handleChange}
                />
                {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
              </div>
              <div className="p-field my-3">
                <label htmlFor="timezone" className="block text-black">
                  Timezone
                </label>
                <Dropdown
                  value={selectedTimeZone}
                  options={timezones}
                  onChange={handleTimeZoneChange}
                  placeholder="Select Timezone"
                  className="w-100"
                />
              </div>
            </>
            : ""}

          {formType == "add" ? (
            <div className="w-100">
              <Button
                type="submit"
                loading={requesting}
                className="w-100 p-button-sm"
                label="Add team"
                autoFocus
              />
            </div>
          ) : (
            <div className="w-100 row m-0">
              <div className="col-6 pl-0 ">
                <Button
                  type="button"
                  label="Cancel"
                  onClick={() => toggleMemberDialog(false)}
                  className="p-button-outlined p-button-secondary p-button-sm w-100"
                />
              </div>
              <div className="col-6 pr-0">
                <Button
                  type="submit"
                  loading={requesting}
                  label="Save"
                  className="p-button-sm p-button-primary w-100"
                  autoFocus
                  onClick={handleSave}
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </Dialog>
  );
}
