export function FreezeUI(options) {
    /** 
    * Freezes the UI
    * options = { 
    *   selector: '.class-name' -> Choose an element where to limit the freeze or leave empty to freeze the whole body. Make sure the element has position relative or absolute,
    *   text: 'Magic is happening' -> Choose any text to show or use the default "Loading". Be careful for long text as it will break the design.
    * }
    */


    // ** Element declaration 
    let freezeHtml = document.createElement('div');
    freezeHtml.classList.add('freeze-ui');
    freezeHtml.innerHTML = '<div class="lds-grid-spinner-wrapper">' +
        '<div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>'
        + '<h2 class="text-primary brand-text mb-0">Loading, please wait...</h2>' +
        '</div>';

    /**
     * Select Element
     **/

    let parent = options ? document.querySelector(options.selector) : document.body;

    /**
     * Set attributes for loading
     **/

    freezeHtml.setAttribute('data-text', options ? options.text : 'Loading');
    if (options) {
        if (document.querySelector(options.selector != 'body')) {
            freezeHtml.style.position = 'absolute';
        }
    }

    // Append element to DOM  -  Freeze the UI
    const existingEls = document.getElementsByClassName('freeze-ui');
    while (existingEls.length == 0) parent.appendChild(freezeHtml);
}

export function UnfreezeUI() {

    /**
     * Unfreezes the UI.
     * No options here.
     **/
    let elements = document.getElementsByClassName('freeze-ui');
    if (elements.length > 0) {
        while (elements.length > 0) {
            elements[0].remove();
        }
    }
}
