import {
    all,
    takeEvery,
    takeLatest,
    put,
    call,
} from "redux-saga/effects";
import { startSubmit, stopSubmit } from "redux-form";
import { push } from 'connected-react-router';
import actions from "../../actions/allActions";
import teamService from '../../services/team';
import {
    addNotification,
    notificationStates
} from "../../components/Notification";
import firebase from "../../config";
const database = firebase.firestore();

export function* GET_TEAMS({ payload }) {
    yield put({ type: "teams/GET_TEAMS_PENDING" })
    try {
        let { success, data } = yield call(teamService.getAllTeamMembers, { ...payload });
        if (success) {
            yield put({ type: "teams/GET_TEAMS_SUCCESS", payload: { success: true, data: [...data] } })
        } else {
            yield put({ type: "teams/GET_TEAMS_FAILED", payload: { success: false, data: "No data available" } })
        }
    } catch (e) {
        yield put({ type: "teams/GET_TEAMS_FAILED", payload: e })
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_TEAMS, GET_TEAMS)
    ]);
}