import Dashboard from "../views/Dashboard";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  loading: state.team.loading,
  teams: state.team.teams,
  teamLoading: state.team.teamLoading,
  profileID: state.auth.profile.id,
  userProfile: state?.auth?.profile,
  activeUserID: state.team.activeUserID,
  selectedDate: state.team.selectedDate,
  selectedTeamID: state.team.selectedTeamID,
  teamMembers: state.team.teamMembers,
  teamLogs: state.team.teamLogs,
  timelogDayRange: state.team.timelogDayRange,
  start: state.team.start,
  end: state.team.end,
  all: state.team.all,
  active: state.team.active,
  projects: state.team.projects
});

const mapDispatchToProps = (dispatch) => ({ dispatch, });

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
