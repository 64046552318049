import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import "./index.css";
import {
  Nav,
  Media,
  Navbar,
  Button,
  NavItem,
  Container,
  ButtonGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Modal as Mod,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import isEmpty from "lodash/isEmpty";
import Modal from "../Modal";
import ExportDataModal from "../Extra/export-data-modal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { UnfreezeUI } from "./../../assets/plugins/nucleo/js/freeze-ui";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  addNotification,
  notificationStates,
} from "../../components/Notification";
import DefaultAvatar from "../../assets/img/svg/avatar.svg";
import { isNull, map } from "lodash";
import { appRoutes } from "./../../routes/index";
import { IoMdArrowRoundBack } from "react-icons/io";
import firebase from "../../config";
import team from "../../services/team";
const database = firebase.firestore();

const AppNavbar = ({ brandText, location }) => {
  const selectedRoute = appRoutes.filter(
    (r) => r.path === location.pathname
  )[0];
  console.log(
    "selectedRoute???????????????",
    selectedRoute,
    "??????????????",
    location
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { name, avatar, email } = useSelector((state) => state.auth.profile);
  const user = useSelector((state) => ({
    userProfile: state.auth?.profile,
    activeCompany: state.auth?.profile?.activeCompany,
    companyList: state.auth?.profile?.companyList,
    activeTeamId: state.team.selectedTeamID,
    activeUserId: state.team.activeUserID,
    all: state.team.all,
  }));
  const teamReducer = useSelector((state) => ({
    loading: state.team.loading,
    activeTeam: state.team.activeTeam,
    activeUserID: state.team.activeUserID,
    members: state.team.teamMembers,
    teams: state.team.teams,
    profileID: state.auth.profile.id,
    all: state.team.all,
    timelogDayRange: state.team.timelogDayRange,
    days: state.team.days,
    start: state.team.start,
    end: state.team.end,
    selectedTeamID: state.team.selectedTeamID,
    week: state.team.week,
    topData: state.team.topData,
    active: state.team.active,
    projects: state.team.projects,
    selectedProject: state.team.selectedProject,
    membersData: state.team.membersData,
    sevenDayData: state.team.sevenDayData,
    thirtyDayData: state.team.thirtyDayData,
  }));
  const member = useSelector((state) => ({
    topProjects: state?.member?.topProjects,
    memberLoading: state?.member?.memberLoading,
    days: state?.member?.days,
    selectMember: state.member.selectMember,
    timelogDayRange: state?.member?.timelogDayRange,
    activeUserID: state?.member?.activeUserID,
    timeData: state?.member?.memberTimeData,
  }));
  console.log("member in navbar", member);
  console.log("teamReducer in navbar", teamReducer);

  const [state, setState] = useState({
    open: "",
    selectedTitle: "",
  });
  const [rSelected, setRSelected] = useState(0);
  const [modal, setModal] = useState(false);
  const [inviteList, setInviteList] = useState(false);

  /**
   * handleChange - handles team dropdown changes
   * @param {*} value
   */
  // console.log("Members : ", user);
  // console.log("rSelected 1", rSelected);
  useEffect(() => {
    console.log("day change");
    if (member.timelogDayRange === 0) {
      setRSelected(0);
    } else if (member.timelogDayRange === 1) {
      setRSelected(1);
    } else if (member.timelogDayRange === 7) {
      setRSelected(7);
    } else if (member.timelogDayRange === 30) {
      setRSelected(30);
    } else if (member.timelogDayRange === "range") {
      setRSelected("range");
    }
  }, [member.timelogDayRange, member.selectMember]);

  // console.log("rSelected 2", rSelected);

  const getUserInvite = async () => {
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", email)
        .get()
        .then((memberData) => {
          const data = memberData.docs.map((res) => res.data());
          if (data[0]?.teamInvites?.length !== 0) {
            setInviteList(true);
            for (let i = 0; i <= data[0]?.teamInvites?.length; i++) {
              localStorage.setItem("ItemCount", data[0]?.teamInvites?.length);
            }
          }
        });
    } catch (error) {
      return error;
    }
  };

  const handleChange = (value, changeInTeam) => {
    let obj = { Team: value.Team, activeTeamid: value.activeTeamid };
    localStorage.setItem("teamData", JSON.stringify(obj));
    dispatch({
      type: "team/SET_SELECT_TEAM_ID",
      payload: {
        selectedTeamID: value.activeTeamid,
        activeUserID: user.userProfile?.id,
        activeTeam: {
          Team: value.Team,
          activeTeamid: value.activeTeamid,
          Role: value?.Role,
        },
        companyId: user.activeCompany.companyId,
      },
    });
    setModal(false);
    setTimeLogDays(rSelected, value.activeTeamid, changeInTeam);
    UnfreezeUI();
    if ("inviteId" in localStorage) {
      const id = localStorage.getItem("inviteId");
      if (id !== null) {
        history.push({
          pathname: `/invites/${id}`,
          state: { access: true },
        });
      }
    }
    if (inviteList === true) {
      addNotification({
        message: "You have pending invites that need your attention!",
        level: notificationStates.success,
      });
      setInviteList(false);
    }
  };

  const handleClick = (e) => {
    localStorage.setItem("teamData", []);
    localStorage.setItem("Team", []);
    e.preventDefault();
    dispatch({
      type: "user/LOGOUT_ACTIVE_ACCOUNT",
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  // console.log("params in setTimeLogDays outside", changeTeam);
  const setTimeLogDays = (days, id = undefined, changeInTeam = false) => {
    console.log("params in setTimeLogDays 1", days, id, changeInTeam);
    console.log("params in setTimeLogDays ", changeInTeam);
    if (member.timelogDayRange === "range" && changeInTeam === true) {
      console.log("in component of navbar range member");
      // setRSelected(0)
      dispatch({
        type: "member/GET_Member_TIMELOG",
        payload: {
          // days: timelogDayRange,
          days: 0,
          start: member?.start,
          end: member?.end,
          teamId: id,
          activeUserID: member.activeUserID,
          selectMember: true,
        },
      });
    } else {
      if (teamReducer.timelogDayRange === "range" && changeInTeam === true) {
        console.log("in component of navbar range");
        setRSelected(0);
        dispatch({
          type: "team/GET_ALL_TEAM_TIMELOG",
          payload: {
            days: 0,
            teamId: id,
            projectId: teamReducer.selectedProject,
            seven: teamReducer.sevenDayData,
            thirty: teamReducer.thirtyDayData,
          },
        });
      } else {
        dispatch({
          type: "team/SET_TIMELOG_DAY_RANGE",
          payload: { days },
        });
        dispatch({
          type: "member/SET_TIMELOG_DAY_RANGE",
          payload: { days },
        });

        dispatch({
          type: "team/SET_TODAY_TIMES",
          payload: {
            selectedTeamID: id,
            day: days,
          },
        });
        dispatch({
          type: "team/SET_TRENDS_DATA",
          payload: {
            selectedTeamID: id,
            week: teamReducer.week,
          },
        });

        if (user.all) {
          dispatch({
            type: "team/GET_ALL_TEAM_TIMELOG",
            payload: {
              days,
              teamId: id,
              projectId: teamReducer.selectedProject,
              seven: teamReducer.sevenDayData,
              thirty: teamReducer.thirtyDayData,
            },
          });
        } else {
          dispatch({
            type: "team/GET_MANAGER_TEAM_COLLECTION",
            payload: {
              days,
              activeTeamId: id,
              activeUserId: user.activeUserId,
            },
          });
        }
      }
    }
  };

  const setTimeLogDateRange = (days, id, start, end, userId) => {
    console.log("SET_TIMELOG_DAY_RANGE userId", days, id, start, end, userId);

    if (selectedRoute?.name === "Teams") {
      console.log("in component of navbar team date-range");
      dispatch({
        type: "team/GET_DATE_RANGE_DATA",
        payload: { days, start, end, id },
      });
    }
    if (selectedRoute?.name === "User") {
      console.log("in component of navbar user date-range");
      dispatch({
        type: "member/GET_DATE_RANGE_DATA",
        payload: {
          days,
          start,
          end,
          id,
          userId,
          selectMember: true,
        },
      });
    }
  };

  const companyTeamHandle = (compId) => {
    localStorage.setItem("teamData", []);
    dispatch({
      type: "team/SWITCH_COMPANY_ACTIVE_TEAM",
      payload: {
        companyId: compId,
        userId: user.userProfile?.id,
      },
    });
  };

  const selectTeam = () => {
    if (
      isEmpty(teamReducer?.active) &&
      !isEmpty(user?.companyList) &&
      teamReducer.teams !== null
    ) {
      setModal(true);
    }
  };

  // const shiftNewData = async () => {
  //   await teamReducer?.projects?.unshift({
  //     data: { deleted: false, project_name: "All" },
  //     id: "",
  //   });
  // };

  useEffect(() => {
    if (!isEmpty(teamReducer?.active)) {
      setTimeLogDays(rSelected, teamReducer?.active?.activeTeamid);
    }
    if (isEmpty(localStorage.getItem("teamData"))) {
      if (teamReducer?.teams?.data?.length === 1) {
        handleChange(teamReducer.teams.data[0]);
      } else if (teamReducer?.teams?.data?.length > 1) {
        selectTeam();
      }
    }
    if (!("Visited" in localStorage)) {
      getUserInvite();
    }
    if (!isEmpty(user.activeCompany.companyId)) {
      dispatch({
        type: "team/GET_PROJECT",
        payload: { companyId: user.activeCompany.companyId },
      });
    }
  }, [user.activeCompany.companyId]);

  return (
    <>
      <Navbar className="navbar-top navbar-light" expand="md" id="navbar-main">
        {/* <Container fluid> */}
        <Nav
          className="align-items-center d-none d-md-flex pl-2 pr-2 mb-2 w-100"
          navbar
        >
          <Row className="pl-3 pt-2 row w-100 align-items-center">
            <div className="w-100 d-flex align-items-center">
              {!selectedRoute?.hasSortingOptions &&
                selectedRoute !== undefined && (
                  <IoMdArrowRoundBack
                    onClick={() => history.goBack()}
                    size={25}
                    className={"mx-3 cursor-pointer"}
                  />
                )}
              {/* <h4 className="h4 mb-0 d-lg-inline-block">
                {selectedRoute?.name}
              </h4> */}
              {selectedRoute?.hasSortingOptions && (
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    nav
                    data-toggle="collapse"
                    className="text-truncate maximum-width"
                  >
                    <span className="nav-link-text">
                      {`Team: `}
                      {!isEmpty(teamReducer.active)
                        ? teamReducer?.active?.Team
                        : "No team selected"}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    {!isEmpty(teamReducer.teams) &&
                      map(teamReducer.teams.data, (value) => {
                        if (value.teamRemoved) return null;
                        return (
                          <DropdownItem
                            key={value.teamId}
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleChange(value, true);
                            }}
                          >
                            <span>{value.Team}</span>
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {user?.companyList && (
                <UncontrolledDropdown nav className="ml-auto">
                  <DropdownToggle
                    caret={user?.companyList?.length > 0 ? true : false}
                    nav
                    data-toggle="collapse"
                    className="text-truncate maximum-width-company"
                  >
                    <span className="nav-link-text font-weight-bold ml-auto">
                      {!isEmpty(user?.activeCompany) ? (
                        user?.activeCompany?.companyName
                      ) : (
                        <Link to={"/add-company"}> Add Company </Link>
                      )}
                    </span>
                  </DropdownToggle>
                  {!isEmpty(user?.companyList) && (
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      {map(user?.companyList, (item) => {
                        return (
                          <DropdownItem
                            key={item?.companyId}
                            onClick={() => companyTeamHandle(item?.companyId)}
                            // will be used later
                            // href={`/dashboard-team?cid=${item?.companyId}`}
                          >
                            <span>{item?.companyName}</span>
                          </DropdownItem>
                        );
                      })}
                      {user?.companyList && user?.companyList.length > 0 && (
                        <DropdownItem
                          key={"add_company"}
                          href="/add-company"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/add-company");
                          }}
                        >
                          <span>Add Company</span>
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  )}
                </UncontrolledDropdown>
              )}

              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={!isEmpty(avatar) ? avatar : DefaultAvatar}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold navprofile">
                        {name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <i className="ni ni-spaceship" />
                    <Link to="/project">Project</Link>
                  </DropdownItem>
                  <DropdownItem href="#pablo" onClick={handleClick}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            {selectedRoute?.hasSortingOptions && (
              <div className="w-100 d-flex align-items-center button-div btn-grp">
                <NavItem className="ml-auto">
                  <ButtonGroup>
                    <Button
                      color="info"
                      size="sm"
                      onClick={() => {
                        setRSelected(0);
                        setTimeLogDays(0, teamReducer?.active?.activeTeamid);
                      }}
                      active={rSelected === 0}
                    >
                      Today
                    </Button>
                    <Button
                      color="info"
                      size="sm"
                      onClick={() => {
                        setRSelected(1);
                        setTimeLogDays(1, teamReducer?.active?.activeTeamid);
                      }}
                      active={rSelected === 1}
                    >
                      Yesterday
                    </Button>
                    <Button
                      color="info"
                      size="sm"
                      onClick={() => {
                        setRSelected(7);
                        setTimeLogDays(7, teamReducer?.active?.activeTeamid);
                      }}
                      active={rSelected === 7}
                    >
                      Last Week
                    </Button>
                    <Button
                      color="info"
                      size="sm"
                      onClick={() => {
                        setRSelected(30);
                        setTimeLogDays(30, teamReducer?.active?.activeTeamid);
                      }}
                      active={rSelected === 30}
                    >
                      Last 30 Days
                    </Button>
                  </ButtonGroup>
                </NavItem>
                <NavItem>
                  <DateRangePicker
                    onApply={(_event, { startDate, endDate }) => {
                      // setRSelected("");
                      setRSelected("range");
                      setTimeLogDateRange(
                        "range",
                        teamReducer?.active?.activeTeamid,
                        startDate.format("DD-MM-YYYY"),
                        endDate.format("DD-MM-YYYY"),
                        member?.activeUserID
                      );
                    }}
                  >
                    <button
                      type="button"
                      className={`btn btn-sm btn-info ${
                        rSelected === "range" ? "active" : ""
                      }`}
                    >
                      Date Range
                    </button>
                  </DateRangePicker>
                </NavItem>
                <NavItem className="mr-auto">
                  <Button
                    color="info"
                    size="sm"
                    onClick={() =>
                      setState({
                        open: "export-data",
                        selectedTitle: "Export Data",
                      })
                    }
                  >
                    Export Data
                  </Button>
                  {/* {selectedRoute.path !== "/dashboard-team" && (
                  )} */}
                </NavItem>
              </div>
            )}
          </Row>
        </Nav>
        {/* </Container> */}
      </Navbar>

      <Modal
        isOpen={state.open === "export-data"}
        size="sm"
        onClose={() => {
          setState({
            open: false,
            selectedTitle: "",
          });
        }}
        title={state.selectedTitle}
      >
        <div style={{ padding: "20px" }}>
          <ExportDataModal
            user={user}
            name={name}
            isTeamDashboard={
              selectedRoute?.path !== "/dashboard-team" ? false : true
            }
          />
        </div>
      </Modal>

      <Mod isOpen={modal} toggle={toggle} style={{ marginTop: "100px" }}>
        <ModalHeader toggle={toggle}>Choose a Team</ModalHeader>
        <ModalBody>
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              {!isEmpty(teamReducer.teams) ? (
                map(teamReducer.teams.data, (value) => {
                  if (value.teamRemoved) return null;
                  return (
                    <Col md={12} className="mt-1">
                      <Button
                        className="modal-button w-100"
                        onClick={() => {
                          handleChange(value);
                        }}
                      >
                        {value.Team}
                      </Button>
                    </Col>
                  );
                })
              ) : (
                <PulseLoader color="#36d7b7" />
              )}
            </Row>
            {/* </Input> */}
          </Container>
        </ModalBody>
      </Mod>
    </>
  );
};

AppNavbar.defaultProps = {
  brandText: "Team Dashboard",
};

export default AppNavbar;
