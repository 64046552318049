import { connect } from "react-redux";
import Projects from "../views/Projects";

const mapStateToProps = (state) => ({
  loading: state.team.loading,
  teams: state.team.teams,
  teamLoading: state.team.teamLoading,
  profileID: state.auth.profile.id,
  activeUserID: state.team.activeUserID,
  selectedDate: state.team.selectedDate,
  selectedTeamID: state.team.selectedTeamID,
  teamMembers: state.team.teamMembers,
  teamLogs: state.team.teamLogs,
  timelogDayRange: state.team.timelogDayRange,
  start: state.team.start,
  end: state.team.end,
  all: state.team.all,
  projects: state.team.projects,
  selectedProject: state.team.selectedProject,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
