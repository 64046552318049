/*eslint-disable*/
import React from "react";
import { Link } from 'react-router-dom'
// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © {new Date().getFullYear()}
              <Link
                className="font-weight-bold ml-1"
                to="/team-dashboard"
              >
                WfhGo
              </Link>
            </div>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
