import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { Redirect, useHistory } from "react-router-dom";
import queryString from "query-string";
import Members from "./members/index";
import Teams from "./teams/index";
import TeamDialogForm from "./teams/TeamDialogForm";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  NavItem,
  Nav,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { usePrevious } from "./../../hooks/userPrevious";
import { useDispatch } from "react-redux";
import DeleteTeamDialog from "./teams/deleteTeamDialog";
import MemberDialogForm from "./members/MemberDialogForm";
import DeleteMemberDialog from "./members/deleteMemberDialog";
import _ from "lodash";

export default function ManageTeams() {
  const { pathname, search, hash, state, key } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { tab } = queryString.parse(search);

  const teamState = useSelector((state) => ({ ...state.team }));
  const userProfile = useSelector((state) => state.auth.profile);
  const userRole = !_.isEmpty(userProfile?.role) ? userProfile?.role : "";
  const selectedCompany = !_.isEmpty(userProfile?.activeCompany)
    ? userProfile?.activeCompany?.companyId
    : "";
  const isMember = !["Admin", "Manager", "Head"].includes(userRole);
  let prevTeams = usePrevious(teamState?.teams);
  let currentTeams = teamState?.teams;

  // ** team declarations
  const [teamDialogForm, toggleTeamDialog] = useState(false);
  const [deleteTeamDialog, toggleDeleteTeamDialog] = useState(false);
  const [selectedTeam, setTeam] = useState({});
  const [action, setAction] = useState("add");

  // ** member declarations
  const [memberDialogForm, toggleMemberDialog] = useState(false);
  const [deleteMemberDialog, toggleDeleteMemberDialog] = useState(false);
  const [selectedMember, setMember] = useState({});

  const switchTab = (name) => {
    history.push(`/manage-teams?tab=${name}`);
  };

  /**
   * Life Cycle Hooks
   */

  useEffect(() => {
    //  * Call to get all members from all teams
    if (currentTeams !== null) {
      if (prevTeams !== currentTeams) {
        if (currentTeams.length > 0) {
          let finalizeData = currentTeams?.filter(
            (item, index) =>
              item.Role == "Manager" ||
              item.Role == "Admin" ||
              item.Role == "Head"
          );
          dispatch({
            type: "teams/GET_MEMBERS",
            payload: { teams: [...finalizeData] },
          });
        }
      }
    }
  }, [teamState]);

  function actionHandler(data, type) {
    setTeam({ ...data });
    if (type == "edit") {
      toggleTeamDialog(true);
      setAction("edit");
    } else {
      toggleDeleteTeamDialog(true);
    }
  }

  function memberActionHandler(data, type) {
    setMember({ ...data });
    if (type == "edit") {
      toggleMemberDialog(true);
    } else {
      toggleDeleteMemberDialog(true);
    }
  }

  if (!tab) {
    return <Redirect to="/manage-teams?tab=teams" />;
  } else {
    if (
      !["teams", "members"].includes(tab.toLowerCase()) ||
      (isMember && tab.toLowerCase() == "members")
    ) {
      return <Redirect to="/" />;
    }
  }

  return (
    <>
      <Container className="pt-md-2" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="m-0 justify-content-center justify-content-lg-between">
                  {!isMember ? (
                    <Nav
                      className="border-0 col-lg-6 col-md-12 col-sm-12 pr-0 mb-2"
                      fill
                      pills
                      tabs
                    >
                      <NavItem className="col-6">
                        <NavLink
                          onClick={() => switchTab("members")}
                          className="shadow-none rounded-0 font-weight-bold"
                          href="#"
                          active={tab.toLowerCase() == "members"}
                        >
                          Members
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-6">
                        <NavLink
                          onClick={() => switchTab("teams")}
                          className="shadow-none rounded-0 font-weight-bold"
                          href="#"
                          active={tab.toLowerCase() == "teams"}
                        >
                          Teams
                        </NavLink>
                      </NavItem>
                    </Nav>
                  ) : (
                    <h3>Teams</h3>
                  )}
                  {/* Only Heads & Admins are able to create teams, invite other users */}
                  {!isMember && userRole != "Manager" ? (
                    <div className="row col-lg-6 col-md-12 col-sm-12 pr-0 pl-4 mb-2 teams-invite-division mr-1">
                      <Button
                        className="invite-member-button p-button p-component p-button-sm col-xl-4 col-lg-6 col-sm-12 col-md-6 mb-2"
                        label="Invite Members"
                        icon="pi pi-send"
                        onClick={() => history.push("/manage-teams/invite")}
                      />
                      <Button
                        className="p-button p-component p-button-sm col-xl-4 col-lg-5 col-sm-12 col-md-5"
                        label="Add Team"
                        icon="pi pi-plus"
                        onClick={() => {
                          setTeam({});
                          setAction("add");
                          toggleTeamDialog(true);
                        }}
                      />
                    </div>
                  ) : null}
                </Row>
              </CardHeader>
              <CardBody>
                {tab.toLowerCase() == "teams" ? (
                  <Teams
                    handleTeamAction={(data, type) => actionHandler(data, type)}
                  />
                ) : (
                  <Members
                    handleMemberAction={(data, type) =>
                      memberActionHandler(data, type)
                    }
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* All Dialog boxes */}
      {teamDialogForm && (
        <TeamDialogForm
          teamDialogForm={teamDialogForm}
          formType={action}
          teamDetails={{ ...selectedTeam }}
          toggleTeamDialog={(bool) => {
            toggleTeamDialog(bool);
          }}
        />
      )}
      {deleteTeamDialog && (
        <DeleteTeamDialog
          deleteTeamDialog={deleteTeamDialog}
          teamDetails={{ ...selectedTeam }}
          toggleDeleteTeamDialog={(bool) => toggleDeleteTeamDialog(bool)}
        />
      )}
      {memberDialogForm && (
        <MemberDialogForm
          formType={"edit"}
          memberDetails={{ ...selectedMember }}
          memberDialogForm={memberDialogForm}
          toggleMemberDialog={(b) => toggleMemberDialog(b)}
        />
      )}
      {deleteMemberDialog && (
        <DeleteMemberDialog
          deleteMemberDialog={deleteMemberDialog}
          memberDetails={{ ...selectedMember }}
          toggleDeleteMemberDialog={(bool) => toggleDeleteMemberDialog(bool)}
        />
      )}
    </>
  );
}
