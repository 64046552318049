import { all, takeEvery, takeLatest, put, call } from "redux-saga/effects";
import actions from "../actions/File";
import file from "../services/upload";
import {
  addNotification,
  notificationStates,
} from "../components/Notification";

export function* UPLOAD_IMAGE({ payload }) {
  try {
    yield call(
      file.uploadImage,
      payload.image,
      payload.name
    );
  } catch (error) {
    addNotification({
      message: error.message,
      level: notificationStates.error,
    });
  }
}

export function* CREATE_POC({ payload }) {
  try {
    yield call(
      file.createPOC,
      payload
    );
  } catch (error) {
    addNotification({
      message: error.message,
      level: notificationStates.error,
    });
  }
}

export function* GET_POC_DATA({ payload }) {
  yield put({
    type: actions.SET_LOADING,
    payload: true,
  });
  try {
    const response = yield call(
      file.getPOCData,
      payload
    );
    if(response){
      yield put({
        type: actions.SET_POC_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    addNotification({
      message: error.message,
      level: notificationStates.error,
    });
  } finally {
    yield put({
      type: actions.SET_LOADING,
      payload: false,
    });
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPLOAD_IMAGE, UPLOAD_IMAGE),
    takeEvery(actions.CREATE_POC, CREATE_POC),
    takeEvery(actions.GET_POC_DATA, GET_POC_DATA)
  ]);
}
