const inviteActions = {
    SEND_INVITE: "manage-teams/SEND_INVITE",
    SEND_INVITE_PENDING: "manage-teams/SEND_INVITE_PENDING",
    SEND_INVITE_SUCCESS: "manage-teams/SEND_INVITE_SUCCESS",
    SEND_INVITE_FAILED: "manage-teams/SEND_INVITE_FAILED",
    SEND_INVITE_CLEANUP: "manage-teams/SEND_INVITE_CLEANUP",


    GET_INVITATION_DETAILS: "user/GET_INVITATION_DETAILS",
    GET_INVITATION_DETAILS_PENDING: "user/GET_INVITATION_DETAILS_PENDING",
    GET_INVITATION_DETAILS_SUCCESS: "user/GET_INVITATION_DETAILS_SUCCESS",
    GET_INVITATION_DETAILS_FAILED: "user/GET_INVITATION_DETAILS_FAILED",
    GET_INVITATION_DETAILS_CLEANUP: "user/GET_INVITATION_DETAILS_CLEANUP",


    ACCEPT_INVITATION: "user/ACCEPT_INVITATION",
    ACCEPT_INVITATION_PENDING: "user/ACCEPT_INVITATION_PENDING",
    ACCEPT_INVITATION_SUCCESS: "user/ACCEPT_INVITATION_SUCCESS",
    ACCEPT_INVITATION_FAILED: "user/ACCEPT_INVITATION_FAILED",
    ACCEPT_INVITATION_CLEANUP: "user/ACCEPT_INVITATION_CLEANUP",


    GET_MEMBERS: "teams/GET_MEMBERS",
    GET_MEMBERS_PENDING: "teams/GET_MEMBERS_PENDING",
    GET_MEMBERS_SUCCESS: "teams/GET_MEMBERS_SUCCESS",
    GET_MEMBERS_FAILED: "teams/GET_MEMBERS_FAILED",
    GET_MEMBERS_CLEANUP: "teams/GET_MEMBERS_CLEANUP",


    GET_TEAMS: "teams/GET_TEAMS",
    GET_TEAMS_PENDING: "teams/GET_TEAMS_PENDING",
    GET_TEAMS_SUCCESS: "teams/GET_TEAMS_SUCCESS",
    GET_TEAMS_FAILED: "teams/GET_TEAMS_FAILED",
    GET_TEAMS_CLEANUP: "teams/GET_TEAMS_CLEANUP",


};

export default {
    ...inviteActions
};
