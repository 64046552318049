import React, { useState } from "react";
import "./expandingImage.css";

const ImageExpander = ({ src, alt = 'Expandable' }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`image-container ${isExpanded ? "expanded" : ""}`} onClick={toggleExpand}>
      <img src={src} alt={alt} className="expandable-image"/>
      {isExpanded && <div className="overlay" onClick={toggleExpand}></div>}
    </div>
  );
};

export default ImageExpander;
