import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Navbar from "../../components/Navbar";
// import Header from "../../components/Header";
import { Container } from "reactstrap";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import { appRoutes } from "../../routes";

const Main = ({ location, children }) => {
  // const hasNavbar = location && location.pathname.includes('dashboard') ? true : false
  const hasNavbar = true;

  return (
    <>
      <Sidebar
        routes={appRoutes}
        location={location}
        logo={{
          innerLink: "/dashboard-team",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content">
        {/* <Navbar location={location} /> */}
        <div className="main-content-child">
          {hasNavbar && <Navbar location={location} />}

          <div className="content">{children} </div>
          <Container fluid>
            <Footer />
          </Container>
        </div>
      </div>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Main);
