/* eslint-disable no-loop-func */
export function CheckAbsent(data, teamMembers) {
    let memberId = [];
    let userData = [];
    let dataToSend = [];
    let absentMember = [];
    let absentMembers = [];
    teamMembers.forEach(element => {
        memberId.push(element.userUID)
    });
    data.forEach(element => {
        if (element.success) {
            userData.push(element.data)
        }
        else {
            userData.push(null)
        }
    });
    userData.forEach((element, i) => {
        let temp = memberId.length
        if (element === null) {
            dataToSend.push("-");
            absentMembers.push("-");
        }
        else {
            const obj = Object.keys(element);
            const a = teamMembers.filter(person => !obj.includes(person.userUID))
            if (Object.keys(element).length === 0) {
                teamMembers.forEach(index => {
                    absentMember.push(index)
                });
            }
            for (let key of Object.keys(element)) {
                if (memberId.includes(key)) {
                    temp = temp - 1;
                }
                teamMembers.forEach(index => {
                    if (index.userUID !== key) {
                        absentMember.push(index)
                    }
                });
                
            }
            absentMembers[i] = a
            absentMember = []
            dataToSend.push(temp)
        }
    })
    return {
        dataToShow: dataToSend,
        absentMembers: absentMembers
    }
}
