import actions from "../actions/Auth";

const initialState = {
  authorized: false,
  loading: false,
  error: {},
  profile: {},
  signUp: {
    pending: false,
    success: false,
    error: false,
    data: {}
  }
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.RESET_SIGNUP_STATE:
      return {
        ...state,
        signUp: {
          pending: false,
          success: false,
          error: false,
          data: {}
        }
      };
    default:
      return state;
  }
}
