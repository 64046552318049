import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { Column } from "primereact/column";
import firebase from "../../config";
import { DataTable } from "primereact/datatable";
import { Button as PrimeButton } from "primereact/button";
import PulseLoader from "react-spinners/PulseLoader";
import { usePrevious } from "../../hooks/userPrevious";
import {
  addNotification,
  notificationStates,
} from "../../components/Notification";

const database = firebase.firestore();

const Leave = () => {
  const teams = useSelector((state) => ({
    projects: state.team.projects,
    active: state.team.active,
    teams: state?.team?.teams,
  }));

  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));
  let prevTeams = usePrevious(teams?.teams);
  let currentTeams = teams?.teams;
  let finalizeData = currentTeams?.data?.filter(
    (item, index) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let { successPayload } = membersState;
  let members = successPayload ? successPayload.data : [];
  const { activeCompany } = useSelector((state) => state.auth.profile);
  const { profile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const typeList = ["Paid Leave", "Sick Leave", "Casual Leave"];
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [day, setDay] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [comment, setComment] = useState("");
  const [regList, setRegList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [type, setType] = useState("");
  const [assignee, setAssignee] = useState("");
  var newArray = [];

  const getRegData = () => {
    const data = []
    setLoading(true);
    database
      .collection("teamDatabase")
      .doc(teams.active.activeTeamid)
      .collection("leaveRequests")
      .where("userId", "==", profile.id)
      .get()
      .then((res) => {
        const doc = res.docs.map((res) => res.data());
        const docId = res.docs.map((res) => res.id);
        for (let i = 0; i < doc.length; i++) {
          data.push({ ...doc[i], id: docId[i] });
        }
        setRegList(data);
        setLoading(false);
      });
  };

  const validate = () => {
    let valid = true
    if(day===0 || day==="" || month===0 || month==="" || year===0 || year===""){
      addNotification({
        message: `Invalid Date Please enter valid date !`,
        level: notificationStates.error,
      })
      valid = false
      return
    }
    if(!type || type === "Please Choose type"){
      addNotification({
        message: `Please Choose type`,
        level: notificationStates.error,
      })
      valid = false
      return
    }
    if(!assignee || assignee === "Please Choose a Manager"){
      addNotification({
        message: `Please Choose Manager`,
        level: notificationStates.error,
      })
      valid = false
      return
    }
    return valid
  }

  // Create leave request
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!validate()) return

    let date = new Date(parseInt(year), parseInt(month), parseInt(day));

    let D = date.getDate();
    let M = date.getMonth();
    let Y = date.getFullYear()

    let leaveDate= `${Y}-${M}-${D}`;

    const path = database.collection("teamDatabase").doc(teams.active.activeTeamid)
    const query = path
      .collection("leaveRequests")
      .where("userId", "==", profile.id)
      .where("leaveDate", "==", leaveDate)
      .where("status", "!=", "Declined");
    query.get().then((querySnap) => {
      if (!querySnap.empty) {
        addNotification({
          message: `You already have submitted a request for leave on the date - ${leaveDate}`,
          level: notificationStates.error,
        });
        return;
      }

      let finalData = {
        userId: profile.id,
        companyID: activeCompany.companyId,
        assignee: assignee !== "" ? assignee : asg,
        status: "Pending",
        type: type || "",
        comment: comment || "No Comment",
        leaveDate: leaveDate,
        requestDate: new Date(),
      };
      return database
        .runTransaction(async (transaction) => {
          const ref = path.collection("leaveRequests").doc()
          const checkRef = path.collection("members").doc(profile.id)
          const memb = database.collection("membersDatabase").doc(assignee !== "" ? assignee : asg);
  
          await transaction?.set(checkRef, {leaveReq: firebase.firestore.FieldValue.arrayUnion(leaveDate)})
          await transaction?.set(ref, finalData);
          await transaction?.update(memb, {
            leaveRequests: firebase.firestore.FieldValue.arrayUnion(
              ref?.id
            ),
          })
        })
        .then((res) => {
          toggle();
          addNotification({
            message: "Request submitted successfully",
            level: notificationStates.success,
          });
          getRegData();
          setAssignee("")
          setDay(0)
          setMonth(0)
          setYear(0)
        })
        .catch((error) => {
          addNotification({
            message: "Request submission failed",
            level: notificationStates.error,
          });
          console.log(error);
        });
    })
  };

  // handle update leave request
  const handleUpdate = async (e) => {
    e.preventDefault();
    if(!validate()) return
    let date = new Date(parseInt(year), parseInt(month), parseInt(day));

    let D = date.getDate();
    let M = date.getMonth();
    let Y = date.getFullYear()
    
    let leaveDate= `${Y}-${M}-${D}`;

    const path = database.collection("teamDatabase").doc(teams.active.activeTeamid)
    const query = path
      .collection("leaveRequests")
      .where("userId", "==", profile.id)
      .where("leaveDate", "==", leaveDate)
      .where("status", "!=", "Declined");
    query.get().then(async(querySnap) => {
      let entryexist = false
      if (!querySnap.empty) {
        querySnap.forEach((doc) => {
            if(selectedData.id !== doc.id){
              addNotification({
                message: `You already have submitted a request for leave on the date - ${leaveDate}`,
                level: notificationStates.error,
            });
            entryexist = true
            return
            }
        });
        if(entryexist) return
      }

      let finalData = {
        userId: profile.id,
        companyID: activeCompany.companyId,
        assignee: assignee !== "" ? assignee : selectedData.assignee,
        status: "Pending",
        type: type || selectedData.type,
        comment: comment || selectedData.comment,
        leaveDate: leaveDate,
        requestDate: new Date(),
      };
  
      const ref = path.collection("leaveRequests").doc(selectedData?.id);
      const membReg = database.collection("membersDatabase")
      return await ref?.update(finalData).then(async (res) => {
        if(!assignee || assignee === selectedData?.assignee){
          toggleUpdate();
          addNotification({
            message: "Request Updated successfully",
            level: notificationStates.success,
          });
          getRegData();
          setAssignee("")
          setDay(0)
          setMonth(0)
          setYear(0)
            return;
        }
           await database.runTransaction(async (transaction) => {
            await transaction?.update(membReg.doc(assignee), {
              leaveRequests: firebase.firestore.FieldValue.arrayUnion(selectedData?.id),
            });
            await transaction?.update(membReg.doc(selectedData?.assignee), {
              leaveRequests: firebase.firestore.FieldValue.arrayRemove(selectedData?.id),
            });
          })
        
        toggleUpdate();
        addNotification({
          message: "Request Updated successfully",
          level: notificationStates.success,
        });
        getRegData();
        setAssignee("")
        setDay(0)
        setMonth(0)
        setYear(0)
      });
    })
  };

  // Delete leave request
  const handleDelete = async () => {
    const ref = database.collection("teamDatabase").doc(teams.active.activeTeamid).collection("leaveRequests").doc(selectedData?.id);
    const membReg = database.collection("membersDatabase")
    return await ref?.delete().then(async (res) => {
      await database.runTransaction(async (transaction) => {
        await transaction?.update(membReg.doc(selectedData?.assignee), {
          leaveRequests: firebase.firestore.FieldValue.arrayRemove(selectedData?.id),
        });
      })
      toggleUpdate();
      addNotification({
        message: "Request deleted successfully",
        level: notificationStates.success,
      });
      getRegData();
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleUpdate = () => {
    setUpdateModal(!updateModal);
  };

  const handleModalClosed = () => {
    setType("")
    setComment("")
    setAssignee("")
    setDay(0)
    setMonth(0)
    setYear(0)
  }

  const fieldIndex = (rowData, column) => {
    return column.rowIndex + 1 + "";
  };

  // const paginatorLeft = (
  //   <PrimeButton type="button" icon="pi pi-refresh" className="p-button-text" />
  // );

  // const paginatorRight = (
  //   <PrimeButton type="button" icon="pi pi-cloud" className="p-button-text" />
  // );

  const navigate = (data) => {
    if (data?.status !== "Declined") {
      setSelectedData(data);
      toggleUpdate();
      let tempDate = data.leaveDate.split("-")
      setDay(tempDate[2])
      setMonth(tempDate[1])
      setYear(tempDate[0])
      setType(data?.type)
      setAssignee(data?.assignee)
      setComment(data?.comment)
    }
  };

  useEffect(() => {
    getRegData();
    if (prevTeams?.data !== currentTeams?.data) {
      if (currentTeams?.data?.length > 0) {
        dispatch({
          type: "teams/GET_MEMBERS",
          payload: { teams: [...finalizeData] },
        });
      }
    }
  }, []);

  const assigneeTempelate = (rowData) => {
    return (
      members?.length > 0 &&
      members?.filter((a) => {
        if (a.userUID == rowData?.assignee) {
          return a;
        }
      })[0]?.userName
    );
  };

  const ReportDateTempelate = (rowData) => {
      let dateObject = rowData.requestDate.toDate()
      var day = dateObject.getDate();
      var month = dateObject.getMonth() + 1;
      var year = dateObject.getFullYear();

      day = day < 10 ? '0' + day : day;
      month = month < 10 ? '0' + month : month;

      let dateString = day + '-' + month + '-' + year;
      return dateString
  }

  const TaskDateTempelate = (rowData) => {
    var dateStringtemp = rowData.leaveDate;
    var parts = dateStringtemp.split('-');
    var yeartemp = parseInt(parts[0]);
    var monthtemp = parseInt(parts[1]) - 1;
    var daytemp = parseInt(parts[2]);

    // Create a Date object
    var dateObject = new Date(yeartemp, monthtemp, daytemp);

    var day = dateObject.getDate();
    var month = dateObject.getMonth() + 1;
    var year = dateObject.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    let dateString = day + '-' + month + '-' + year;
    return dateString

  }

  var asg = "";
  if (members?.length > 0) {
    // el.role === "Head"
    newArray = members?.filter(function (el) {
      return el.role === "Admin" || el.role === "Manager" ;
    });
    asg = newArray?.length > 0 ? newArray[0].userUID : "";
  }

  const manager = (id) => {
    let result = newArray.find((user) => user.userUID === id)
    return result ? result.userName : ""
  }

  return (
    <>
      <div style={{ paddingTop: "5rem!important" }}>
        <Container className="pt-md-2" fluid>
          <PrimeButton className="add-request-btn" onClick={toggle}>
            {" "}
            + Add Request
          </PrimeButton>
          {loading ? (
            <div className="w-100 d-flex justify-content-center">
              <PulseLoader color="#36d7b7" />
            </div>
          ) : (
            <Card className="bg-secondary shadow mt-2">
              <DataTable
                className="mt-2 task-table task-page-table"
                responsiveLayout="scroll"
                value={regList}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                style={{ cursor: "pointer" }}
                rows={20}
                rowsPerPageOptions={[10, 20, 50]}
                // paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
                onRowClick={(e) => navigate(e?.data)}
              >
                <Column
                  field="#"
                  header="ID"
                  body={fieldIndex}
                  className="first-col td-start"
                ></Column>
                <Column
                  field="comment"
                  header="Comment"
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="type"
                  header="Type"
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="leaveDate"
                  header="Leave Date"
                  body={TaskDateTempelate}
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="reportDate"
                  header="Report Date"
                  body={ReportDateTempelate}
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="assignee"
                  header="Assignee"
                  body={assigneeTempelate}
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  sortable
                  className="td-start"
                ></Column>
              </DataTable>
            </Card>
          )}
        </Container>
      </div>

      {/* Modal for creating leave request */}
      <Modal isOpen={modal} toggle={toggle} onClosed={handleModalClosed} className="regulationModal">
        <ModalHeader toggle={toggle}>Leave Form</ModalHeader>
        <ModalBody className="p-5">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="exampleTask">Type</Label>
              {typeList.length > 0 ? (
                <Input
                  type="select"
                  name="type"
                  id="type"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option key={"key"}>Please Choose type</option>
                  {typeList?.map((m, i) => {
                    return (
                      <>
                        <option
                          key={i}
                          value={m}
                        >
                          {m}
                        </option>
                      </>
                    );
                  })}
                </Input>
              ) : (
                <span>
                  <Spinner size="sm" /> Loading...
                </span>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="exampleNumber">Comment</Label>
              <Input
                type="text"
                name="comment"
                placeholder="Please Write a Comment Here..."
                id="text"
                value={comment || ""}
                onChange={(e) => setComment(e.target.value)}
              />
            </FormGroup>
            {newArray?.length > 0 && (
              <FormGroup>
                <Label for="exampleNumber">Manager</Label>
                <Input
                  type="select"
                  name="assignee"
                  id="task"
                  value={assignee}
                  onChange={(e) => setAssignee(e.target.value)}
                >
                  <option key={"key"}>Please Choose a Manager</option>
                  {newArray?.map((m) => {
                    return (
                      <option key={m?.userUID} value={m?.userUID}>
                        {m?.userName}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            )}
            <Row>
              <Col md={4}>
                {" "}
                <FormGroup>
                  <Label for="exampleNumber">Date</Label>
                  <Input
                    type="number"
                    name="day"
                    id="number"
                    min="1"
                    max="31"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                {" "}
                <FormGroup>
                  <Label for="exampleNumber">Month</Label>
                  <Input
                    type="number"
                    name="month"
                    id="number"
                    min="1"
                    max="12"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                {" "}
                <FormGroup>
                  <Label for="exampleNumber">Year</Label>
                  <Input
                    type="number"
                    name="year"
                    id="number"
                    min="2023"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Modal for updating and deleting leave request */}
      <Modal
        isOpen={updateModal}
        toggle={toggleUpdate}
        className="regulationModal"
        style={{ minWidth: "55%" }}
        onClosed={handleModalClosed}
      >
        <ModalHeader toggle={toggleUpdate}>
          Update/Delete Leave Form
        </ModalHeader>
        <ModalBody className="p-5">
          <Row>
            <Col md={6}>
              <h4>
                Leave Type :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {selectedData?.type}
                </span>
              </h4>
              <h4>
                Comment :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {selectedData?.comment}
                </span>
              </h4>
              <h4>
                Leave Date :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {selectedData?.leaveDate}
                </span>
              </h4>
              <h4>
                Manager :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {manager(selectedData?.assignee) || ""}
                </span>
              </h4>
            </Col>
            <Col md={6}>
              <Form onSubmit={handleUpdate}>
                <FormGroup>
                  <Label for="exampleTask">Type</Label>
                  {typeList.length > 0 ? (
                    <Input
                      type="select"
                      name="type"
                      id="type"
                      value={ type || selectedData?.type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      <option key={"key"}>Please Choose type</option>
                      {typeList?.map((m, i) => {
                        return (
                          <>
                            <option
                              key={i}
                              value={m}
                            >
                              {m}
                            </option>
                          </>
                        );
                      })}
                    </Input>
                  ) : (
                    <span>
                      <Spinner size="sm" /> Loading...
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="exampleNumber">Comment</Label>
                  <Input
                    type="text"
                    name="details"
                    placeholder="Please Write a Comment Here..."
                    id="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </FormGroup>
                {newArray?.length > 0 && (
                  <FormGroup>
                    <Label for="exampleNumber">Manager</Label>
                    <Input
                      type="select"
                      name="assignee"
                      id="task"
                      value={assignee ? assignee : selectedData?.assignee}
                      onChange={(e) => setAssignee(e.target.value)}
                    >
                      <option key={"key"}>Please Choose a Manager</option>
                      {newArray?.map((m) => {
                        return (
                          <option key={m?.userUID} value={m?.userUID}>
                            {m?.userName}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                )}
                <Row>
                  <Col md={4}>
                    {" "}
                    <FormGroup>
                      <Label for="exampleNumber">Date</Label>
                      <Input
                        type="number"
                        name="day"
                        id="number"
                        min="1"
                        max="31"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <FormGroup>
                      <Label for="exampleNumber">Month</Label>
                      <Input
                        type="number"
                        name="month"
                        id="number"
                        min="1"
                        max="12"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <FormGroup>
                      <Label for="exampleNumber">Year</Label>
                      <Input
                        type="number"
                        name="year"
                        id="number"
                        min="2023"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {selectedData?.status === "Pending" && (
                  <>
                    <Button color="primary">Update</Button>
                    <Button onClick={handleDelete} color="danger">
                      Delete
                    </Button>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Leave;
