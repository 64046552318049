import React, { Children, useState } from "react";
import { Field, reduxForm } from "redux-form//immutable";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { validate } from "./utils";
import it from "date-fns/esm/locale/it/index.js";

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => (
  <div>
    <label className="control-label">{label}</label>
    <div>
      <input
        {...input}
        placeholder={label}
        type={type}
        className="form-control"
      />
      {touched &&
        ((error && <span className="text-danger">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

const renderStatusField = ({
  input,
  label,
  type,
  children,
  meta: { touched, error, warning },
}) => (
  <div>
    <label className="control-label">{label}</label>
    <div>
      <select
        {...input}
        placeholder={label}
        type={type}
        className="form-control"
      >
        {children}
      </select>
      {touched &&
        ((error && <span className="text-danger">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

const TaskForm = ({
  handleCloseEventModal,
  handleSubmit,
  submitting,
  members,
  project,
}) => {
  const team = useSelector((state) => ({
    teams: state.team.teams,
    activeTeam: state.team.activeTeam,
    selectedTeamId: state.team.selectedTeamID,
    projects: state.team.projects,
  }));

  const { activeCompany } = useSelector((state) => state.auth.profile);
  const dispatch = useDispatch();
  const statusOptions = ["TO DO", "IN PROGRESS", "QA", "DONE"];
  const priorityOptions = ["P1", "P2", "P3", "P4"];
  const newData = [];
  console.log("Members : ", members);
  const newMembers = [...members];

  team.projects.map((item) => {
    if (!item.data.deleted) {
      const obj = {
        name: item.data.project_name,
        id: item.id,
      };
      newData.push(obj);
    }
  });

  return (
    <div>
      <form
        onSubmit={handleSubmit((values) => {
          handleCloseEventModal();
          console.log("values", values);
          dispatch({
            type: "team/ADD_TASKS",
            payload: {
              companyId: activeCompany.companyId,
              projectId: "activeCompany.companyId",
              value: { ...values, teamId: team.selectedTeamId },
            },
          });
        })}
      >
        <Field
          name="team"
          component={renderStatusField}
          label="Team"
          className="mb-3 form-control  form-control-sm"
          placeholder="Project Title"
        >
          <option>Select team</option>
          {newData.map((x) => (
            <option key={x.id} value={x.id}>
              {x.name}
            </option>
          ))}
        </Field>
        <Field
          type="text"
          name="task"
          label="Title"
          className="mb-3 form-control form-control-sm"
          component={renderField}
          placeholder="Task Title"
        />
        <Field
          type="text"
          name="summery"
          label="Summery"
          className="mb-3 form-control form-control-sm"
          component={renderField}
          placeholder="Task Summery"
        />
        <Field
          name="status"
          component={renderStatusField}
          label="Status"
          className="mb-3 form-control  form-control-sm"
          placeholder="status"
        >
          <option>Select status</option>

          {statusOptions.map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </Field>
        <Field
          name="priority"
          component={renderStatusField}
          label="Priority"
          className="mb-3 form-control  form-control-sm"
          placeholder="Priority"
        >
          <option>Select priority</option>

          {priorityOptions.map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </Field>
        <Field
          name="assignee"
          component={renderStatusField}
          label="Assignee"
          className="mb-3 form-control  form-control-sm"
          placeholder="assignee"
        >
          <option>Select assignee</option>

          {newMembers.map((x, i) => (
            <option key={i} value={x.userUID}>
              {x.userName}
            </option>
          ))}
        </Field>
        <Button
          disabled={submitting}
          color="primary"
          type="submit"
          size="sm"
          className="mt-2"
        >
          Create Task
        </Button>
      </form>
    </div>
  );
};
export default reduxForm({
  form: "TaskForm",
  validate,
})(TaskForm);
