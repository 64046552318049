import firebase from '../config';

const database = firebase.firestore();

async function addNewCompany(payload) {
  const personalId = Math.random().toString(36).substring(2, 32);
  try {
    const batch = database.batch();
    const companyRef = database.collection('companyDatabase').doc();
    const memberRef = database
      .collection('membersDatabase')
      .doc(payload.createdBy.userId);
    const teamDBRef = database.collection('teamDatabase').doc();
    const companyTeamsRef = database
      .collection('companyDatabase')
      .doc(companyRef?.id)
      .collection('teams')
      .doc(teamDBRef.id);
    const cityRef = database.collection('companyDatabase').doc(companyRef?.id);
    const doc = await cityRef.get();
    const companyTeam = doc.data();

    // Add data to company Database
    batch.set(
      companyRef,
      {
        name: payload.companyDetails.name,
        // size: payload.companyDetails.size,
        location: payload.companyDetails.location,
        screenshot: false,
        createdBy: payload.createdBy.userId,
        admins: [payload.createdBy.userId],
      },
      { merge: true }
    );

    // Update team details
    batch.set(
      teamDBRef,
      {
        teamName: 'Personal',
        ownedBy: companyRef.id,
        createdBy: payload.createdBy.userId,
        memberDetails: {
          [payload.createdBy.userId]: {
            userEmailid: payload.createdBy.email,
            userName: payload.createdBy.name,
            userType: payload.createdBy.role,
          },
        },
      },
      { merge: true }
    );
    // Add data to member database
    batch.set(
      memberRef,
      {
        accountEmailid: payload.createdBy.email,
        accountFullName: payload.createdBy.name,
      },
      { merge: true }
    );

    // Add data to company list of member
    const memberCompanyListRef = memberRef
      .collection('companyList')
      .doc(companyRef.id);
    batch.set(
      memberCompanyListRef,
      {
        role: payload.createdBy.role,
        companyName: payload.companyDetails.name,
        companyId: companyRef.id,
      },
      { merge: true }
    );
    // Add team to teamList in related CompanyList
    const memberTeamListRef = memberCompanyListRef
      .collection('teamList')
      .doc(teamDBRef.id);
    batch.set(
      memberTeamListRef,
      {
        Role: payload.createdBy.role,
        Team: 'Personal',
        email: payload.createdBy.email,
        activeTeamid: memberTeamListRef.id,
      },
      { merge: true }
    );
    // ** Updates to the companyDatabase
    batch.set(
      companyTeamsRef,
      {
        teamId: teamDBRef.id,
        // members: membersIDs.length > 0 ? membersIDs : {},
        // ...(membersIDs.length > 0 ? { members: membersIDs } : {})
      },
      { merge: true }
    );

    batch.update(cityRef, {
      teams: [teamDBRef.id],
    });
    return batch
      .commit()
      .then(() => {
        return {
          success: true,
          data: {
            message: 'Company created successfully!',
            data: { id: companyRef.id, ...payload.companyDetails.name },
          },
        };
      })
      .catch((e) => {
        throw e;
      });
  } catch (error) {
    throw error;
  }
}

export default {
  addNewCompany,
};
