import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import firebase from "../../config";
import { withRouter, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import BlockUi from "react-block-ui";
import { useDispatch } from "react-redux";

const database = firebase.firestore();

const InviteList = (auth) => {
  const [inviteData, setInviteData] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [isList, setIsList] = useState(true);
  const [empty, setEmpty] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // get User Data
  const getUser = async () => {
    setInviteData([]);
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", auth?.auth?.profile?.email)
        .get()
        .then((memberData) => {
          const data = memberData.docs.map((res) => res.data());
          if (data[0]?.teamInvites?.length === 0) {
            setIsList(true);
          } else {
            setEmpty(false);
            for (let i = 0; i <= data[0]?.teamInvites?.length; i++) {
              try {
                database
                  .collection("userInvites")
                  .doc(data[0].teamInvites[i])
                  .get()
                  .then((response) => {
                    if (response.data() !== undefined) {
                      setInviteData((old) => [
                        ...old,
                        {
                          data: response.data(),
                          docId: data[0].teamInvites[i],
                        },
                      ]);
                    }
                  });
              } catch (error) {
                return error;
              }
            }
          }
        });
    } catch (error) {
      return error;
    }
    setIsList(false);
  };

  const openModal = (docId) => {
    history.push(`invites/${docId}`);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    localStorage.removeItem("ItemCount");
    localStorage.setItem("Visited", true);
    if (auth?.auth?.profile?.companyList?.length === 1) {
      if ("list" in localStorage) {
        const list = localStorage.getItem("list");
        localStorage.removeItem("list");
        if (list === 1) {
          localStorage.setItem("teamData", []);
          dispatch({
            type: "team/SWITCH_COMPANY_ACTIVE_TEAM",
            payload: {
              companyId: auth?.auth?.profile?.companyList[0].companyId,
              userId: auth?.auth?.profile?.id,
            },
          });
        }
      }
    }
    getUser();
  }, []);

  return (
    <div style={{ paddingTop: "5rem!important" }}>
      {/* {userData !== [] ? ( */}
      <Container className="pt-md-2" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            <Nav tabs>
              <NavItem style={{ cursor: "pointer" }}>
                <NavLink
                  className={"1"}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Team Invites
                </NavLink>
              </NavItem>
              <NavItem style={{ cursor: "pointer" }}>
                <NavLink
                  className={"2"}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Project Invites
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    {!isList ? (
                      inviteData.length !== 0 ? (
                        <>
                          <Table hover responsive bordered size="sm" striped>
                            <thead>
                              <tr>
                                <th>
                                  <h4>Id</h4>
                                </th>
                                <th>
                                  <h4>Role</h4>
                                </th>
                                <th>
                                  <h4>InvitedBy</h4>
                                </th>
                                <th>
                                  <h4>Company</h4>
                                </th>
                                <th>
                                  <h4>Team</h4>
                                </th>
                                <th>
                                  <h4>Action</h4>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {inviteData.map((res, key) => {
                                return (
                                  res?.data?.invitedAs !== "Client" &&
                                  !res?.data?.declined && (
                                    <tr>
                                      <th scope="row">{key + 1}</th>
                                      <td>{res?.data?.invitedAs}</td>
                                      <td>{res?.data?.invitedBy?.fullName}</td>
                                      <td>
                                        {
                                          res?.data?.invitedBy?.companyDetails
                                            ?.companyName
                                        }
                                      </td>
                                      <td>{res?.data?.invitedTo?.teamName}</td>
                                      <td>
                                        <Button
                                          onClick={() => openModal(res?.docId)}
                                        >
                                          Proceed
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        empty && (
                          <div
                            className={"w-100 d-flex justify-content-center"}
                          >
                            <h1>No invites found!</h1>
                          </div>
                        )
                      )
                    ) : (
                      <div className="loader-container">
                        <BlockUi
                          tag="div"
                          blocking
                          message="Loading, please wait"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    {!isList ? (
                      inviteData.length !== 0 ? (
                        <>
                          <Table hover responsive bordered size="sm" striped>
                            <thead>
                              <tr>
                                <th>
                                  <h4>Id</h4>
                                </th>
                                <th>
                                  <h4>Role</h4>
                                </th>
                                <th>
                                  <h4>InvitedBy</h4>
                                </th>
                                <th>
                                  <h4>Company</h4>
                                </th>
                                <th>
                                  <h4>Project</h4>
                                </th>
                                <th>
                                  <h4>Action</h4>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {inviteData.map((res, key) => {
                                return (
                                  res?.data?.invitedAs === "Client" &&
                                  !res?.data?.declined && (
                                    <tr>
                                      <th scope="row">{key + 1}</th>
                                      <td>{res?.data?.invitedAs}</td>
                                      <td>{res?.data?.invitedBy?.fullName}</td>
                                      <td>
                                        {
                                          res?.data?.invitedBy?.companyDetails
                                            ?.companyName
                                        }
                                      </td>
                                      <td>{res?.data?.project[0]?.label}</td>
                                      <td>
                                        <Button
                                          onClick={() => openModal(res?.docId)}
                                        >
                                          Proceed
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        empty && (
                          <div
                            className={"w-100 d-flex justify-content-center"}
                          >
                            <h1>No invites found!</h1>
                          </div>
                        )
                      )
                    ) : (
                      <div className="loader-container">
                        <BlockUi
                          tag="div"
                          blocking
                          message="Loading, please wait"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default connect(({ auth }) => ({ auth }))(withRouter(InviteList));
