import types from "../../actions/Tasks";

const initialState = {
  allData: [],
  tempData: [],
  searchData: [],
  filterAssigneeData: [],
  filterStatusData: [],
  filterPriorityData: [],
};

const allTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ALL_DATA:
      return {
        ...state,
        allData: action.payload,
      };
    case types.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      };
    case types.SET_SEARCH_ASSIGNEE_DATA:
      return {
        ...state,
        filterAssigneeData: action.payload,
      };
    case types.SET_SEARCH_STATUS_DATA:
      return {
        ...state,
        filterStatusData: action.payload,
      };
    case types.SET_SEARCH_PRIORITY_DATA:
      return {
        ...state,
        filterPriorityData: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default allTaskReducer;
