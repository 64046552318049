export const START_IMAGE_UPLOAD = 'START_IMAGE_UPLOAD';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_FAILURE = 'IMAGE_UPLOAD_FAILURE';

export const startImageUpload = () => ({
  type: START_IMAGE_UPLOAD
})
;

export const imageUploadSuccess = () => ({
  type: IMAGE_UPLOAD_SUCCESS,
});
;

export const imageUploadFailure = () => ({
  type: IMAGE_UPLOAD_FAILURE
});
