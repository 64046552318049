import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { errorRenderer } from './../../../utils/utility';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import companyService from '../../../services/company';
import { countryList } from './../../../config/countries';
import { TimePicker } from 'antd';
import moment from 'moment-timezone';
import {
  addNotification,
  notificationStates,
} from '../../../components/Notification';
// import TimezoneSelect from "react-timezone-select";

export default function AddCompanyForm() {
  /**
   * Form Schema for validation
   */

  const FormSchema = Yup.object().shape({
    company_name: Yup.string()
      .required('Company name is required')
      .min(3, 'Name must contains 3 letters'),
    company_location: Yup.string().required('Company location is required'),
    time_zone: Yup.string().required('Timezone is required').nullable(),
    time_range: Yup.array().required('Time range is required').nullable(),
  });

  /**
   * Declarations
   */
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(FormSchema) });
  const companyLocation = watch('company_location');
  const timeZone = watch('time_zone');
  console.log('timeZone', timeZone);
  const timeRange = watch('time_range');
  const history = useHistory();
  const dispatch = useDispatch();
  const authState = useSelector((state) => ({ ...state.auth }));
  // const [timeRange, setTimeRange] = useState(null);
  const isUserAuthorized = authState.authorized;
  const countryOptions = countryList.map((i) => ({
    label: i.country,
    value: i.country,
  }));

  // useMutations
  const addCompanyRequest = useMutation(
    async (payload) => {
      return await companyService.addNewCompany(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          if (authState?.profile?.companyList.length === 0) {
            localStorage.setItem('teamData', []);
            dispatch({
              type: 'team/SWITCH_COMPANY_ACTIVE_TEAM',
              payload: {
                companyId: data.data.data.id,
                userId: authState.profile.id,
              },
            });
          } else {
            dispatch({ type: 'user/LOAD_CURRENT_ACCOUNT' });
            history.push(`/dashboard-team?select=1`);
          }
        }
      },
    }
  );
  const { isLoading, isError, isSuccess, error, data } = addCompanyRequest;

  /**
   * Life Cycle
   */
  useEffect(() => {
    register('company_address');
    register('company_location');
    register('time_zone');
    register('time_range');
    setValue('company_location', '');
    setValue('company_address', '');
    setValue('time_zone', '');
    setValue('time_range', '');

    return function () {
      addCompanyRequest.reset();
    };
  }, []);

  /**
   * handleAddCompany - handle Add Company Process
   * @param {*} data - Company details
   */

  const handleAddCompany = (data) => {
    const { company_name, company_location, time_zone, time_range } = data;
    const { companyList } = authState.profile;
    let doesCompanyExist = false;
    if (companyList) {
      doesCompanyExist = companyList.some(
        (i) => i.companyName.toLowerCase() == company_name.toLowerCase()
      );
    }
    const requestPayload = {
      companyDetails: {
        name: company_name,
        location: company_location,
        timezone: time_zone,
        startTime: time_range[0],
        endTime: time_range[1],
      },
      createdBy: {
        name: authState?.profile?.name,
        email: authState?.profile?.email,
        userId: authState?.profile?.id,
        role: 'Admin',
      },
    };
    if (doesCompanyExist) {
      addNotification({
        message: 'Company already exists!',
        level: notificationStates.error,
      });
    }
    if (!doesCompanyExist) {
      // Add request
      addCompanyRequest.mutate({ ...requestPayload });
    }
  };

  // Redirect unAuthorized users to home page
  useEffect(() => {
    if (!authState.loading && !isUserAuthorized) {
      history.push('/');
    }
  }, [authState]);

  return (
    <>
      <Form role={'form'} onSubmit={handleSubmit(handleAddCompany)}>
        <div className="p-field my-3">
          <h5>What's the name of your Company?</h5>
          <Controller
            name="company_name"
            rules={{ required: 'Company name is required' }}
            control={control}
            render={({ field, fieldState }) => (
              <InputText
                id="company_name"
                {...field}
                onChange={(e) => {
                  setValue('company_name', e.target.value);
                  clearErrors();
                }}
                placeholder="Company name"
                className={classNames(
                  { 'p-invalid': errors?.company_name },
                  'p-inputtext-sm w-100'
                )}
              />
            )}
          />
          {errorRenderer(errors, 'company_name')}
        </div>
        <div className="p-field my-3">
          <h5>Where is your company located?</h5>
          <Dropdown
            value={companyLocation}
            options={countryOptions}
            onChange={(e) => {
              setValue('company_location', e.target.value);
              clearErrors('company_location');
            }}
            placeholder="Select a country"
            className={classNames(
              { 'p-invalid': errors?.company_location },
              'w-100'
            )}
          />
          {errorRenderer(errors, 'company_location')}
        </div>
        <div className="p-field my-3">
          <h5>Where is your timezone?</h5>
          <Dropdown
            value={timeZone}
            options={moment.tz.names()}
            onChange={(e) => {
              console.log(e);
              setValue('time_zone', e.target.value);
              clearErrors('time_zone');
            }}
            placeholder="Select a timezone"
            className={classNames({ 'p-invalid': errors?.time_zone }, 'w-100')}
          />
        </div>
        {errorRenderer(errors, 'time_zone')}
        <div className="p-field my-3">
          <h5>Where is your office timerange?</h5>
          <TimePicker.RangePicker
            onChange={(time, timeString) => {
              setValue('time_range', timeString);
              clearErrors('time_range');
            }}
            className={classNames({ 'p-invalid': errors?.time_range }, 'w-100')}
          />
        </div>
        {errorRenderer(errors, 'time_range')}
        <div>
          <div className="row mt-1">
            <div className="col-6 pl-0 ">
              <Button
                type="button"
                onClick={() => history.push(`/dashboard-team?select=0`)}
                label="May be Later"
                className="p-button-outlined p-button-secondary p-button w-100"
              />
            </div>
            <div className="col-6 pr-0">
              <Button
                type="submit"
                label="Proceed"
                loading={isLoading}
                className="p-button p-button-primary w-100"
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
