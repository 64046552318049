import React, { useEffect, useLayoutEffect } from "react";
import {
    Card,
    Button,
    CardHeader,
    CardBody,
    Col,
    Spinner,
} from "reactstrap";
import AppLogo from './../../assets/img/brand/logo.png';
import { Link } from "react-router-dom";
import BlockUi from 'react-block-ui';
import PulseLoader from "react-spinners/PulseLoader";
import { Redirect, useLocation, useHistory } from "react-router"
import queryString from "query-string"
import { useDispatch, useSelector } from 'react-redux';
import { css } from "@emotion/core";
import { FreezeUI, UnfreezeUI } from './../../assets/plugins/nucleo/js/freeze-ui';
import { call } from 'redux-saga/effects';
import invitationService from "../../services/invitation"
import authService from "../../services/auth"
import { useQuery } from 'react-query';
import InvitationForm from './form';

const override = css`
  text-align: center;
  display: block;
  margin: 0 auto;
  border-color: green;
`;

function ExpiredMessage({ isUserAuthorized }) {
    return (<>
        <small>Invitation Link is expired</small>
        <div className="text-center">
            <Link to="/auth/sign-up" className="mt-4 w-50 btn btn-default" color="default" type="button">
                {isUserAuthorized ? "Home" : "Join now"}
            </Link>
        </div>
    </>)
}

export default function InvitationPage() {


    const location = useLocation()
    const dispatch = useDispatch()
    const { i: invitationId, ref } = queryString.parse(location.search);
    const authState = useSelector((state) => ({ ...state.auth }));
    /**
    * Fetch Invitation Details
    */
    const {
        isFetching: isInFetching,
        isError: isInError,
        isSuccess: isInSuccess,
        isFetched: isInFetched,
        isIdle: isInIdle,
        error: InError,
        data: invitationData } = useQuery(
            ['getInvitationDetails', invitationId],
            async () => {
                const { success, data } = await invitationService.getInvitationDetails(invitationId)
                if (success) return { success, data };
                else throw ' I did not found any invitation for you.'
            },
            {
                enabled: !!invitationId,
                select: (data) => data?.data,
            }
        );
    const { isFetching: isAuthFetching,
        isError: isAuthError,
        isSuccess: isAuthSuccess,
        isFetched: isAuthFetched,
        isIdle: isAuthIdle,
        error: AuthError,
        data: authUserData } = useQuery('currentAccount', async () => await authService.currentAccount());


    const isSameUser = authUserData?.email == invitationData?.userDetails?.email ?? false;
    const isUserRegistered = invitationData?.userDetails?.registered ?? false;
    const isUserAuthorized = authUserData?.email ? true : false

    if (isInFetching || isAuthFetching) {
        FreezeUI();
    }
    if (isInFetched && isAuthFetched) {
        UnfreezeUI();
    }

    // useEffect(() => {
    //     if (!invitationId) {
    //         return <Redirect to="/" />
    //     }
    // }, [])

    useEffect(() => {
    }, [])


    useEffect(() => {
        if (isInSuccess && isInFetched && invitationData) {
            if (!invitationData.accepted) {
                localStorage.setItem('pendingInvitation', JSON.stringify({ ...invitationData, invitationId }))
            }
        }
    }, [isInFetched, invitationData, isInSuccess])

    if (!invitationId) {
        return <Redirect to="/" />
    }

    if ((isAuthFetched && isAuthSuccess) && (isInFetched && isInSuccess)) {
        if (!isUserAuthorized) {
            if (isUserRegistered) {
                return <Redirect to={`/auth/login?i=${invitationId}`} />
            } else {
                return <Redirect to={`/auth/sign-up?i=${invitationId}`} />
            }
        }
    }


    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent border-0">
                        <div className="text-center">
                            <img className="auth-app-logo" src={AppLogo} alt="workfromhome" />
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 pb-lg-5 pt-lg-1">
                        <div className="text-muted text-center mt-2">
                            <h2>Invitation</h2>
                            {(isInSuccess && isAuthSuccess) &&
                                <>
                                    {
                                        (isUserAuthorized == false)
                                            ? (invitationData?.accepted)
                                                ? <ExpiredMessage isUserAuthorized={isUserAuthorized} />
                                                : <p className="font-weight-bold mt-1">
                                                    Please <Link to='/auth/login'> login</Link> or <Link to="/auth/sign-up"> sign up</Link> to accept this invitation
                                                </p>
                                            : (invitationData?.accepted)
                                                ? <ExpiredMessage isUserAuthorized={isUserAuthorized} />
                                                : (isSameUser)
                                                    ? <InvitationForm
                                                        invitationData={invitationData}
                                                        authState={authState}
                                                        refLink={ref}
                                                        invitationId={invitationId}
                                                    />
                                                    : <p className="font-weight-bold mt-1">
                                                        You have opened wrong invitation link. Please <Link to='/auth/login'> login</Link> to relevant account to accept this invitation.
                                                    </p>
                                    }
                                </>
                            }
                            {
                                (isInError || isAuthError) && <ExpiredMessage isUserAuthorized={isUserAuthorized} />
                            }

                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};
