import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Col,
    Row,
    Label,
    Form,
} from "reactstrap";
import _ from "lodash";
import { PropTypes } from "prop-types";
import { FormGroup, InputGroupAddon, InputGroupText, Input, Button } from 'reactstrap';
import firebase from "../../config";

const ManageSettings = ({ threshold, loading }) => {
    const database = firebase.firestore();

    const [newthreshold, setThreshold] = useState("");
    const dispatch = useDispatch();

    const userProfile = useSelector(state => state.auth.profile);

    useEffect(() => {
        if (userProfile?.activeCompany !== undefined) {
            dispatch({
                type: "team/GET_THRESHOLD",
                payload: {
                    companyId: !_.isEmpty(userProfile?.activeCompany) ? userProfile?.activeCompany?.companyId : '',
                },
            })
        }
    }, [threshold]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: "team/SET_THRESHOLD",
            payload: {
                companyId: !_.isEmpty(userProfile?.activeCompany) ? userProfile?.activeCompany?.companyId : '',
                threshold: newthreshold
            },
        });
        if (!loading) {
            setThreshold("")
        }
    }

    const [checked, setChecked] = useState();
    useEffect(() => {
        database.collection("companyDatabase")
        .doc(userProfile?.activeCompany?.companyId || "no id")
        .get()
        .then((doc) => {
            if (doc.exists) {
                let jsonData = doc.data();
                setChecked(jsonData.screenshot)
            } else {
                console.log("No such document!");
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });
    }, []);

    const [disabled, setDisabled] = useState(false)

    const handleScreenShots = (e) => {
        setDisabled(true);
        const newValue = e.target.checked;
        setChecked(newValue)
        let newData={
            screenshot: newValue
        }
        database.collection("companyDatabase")
        .doc(userProfile?.activeCompany.companyId)
        .set(
            newData,
            { merge: true }
        )
        .then(() => {
            return database.collection("companyDatabase").doc(userProfile?.activeCompany.companyId).get();
        })
        .then((doc) => {
            if (doc.exists) {
                console.log("Updated screenShot value:", doc.data().screenshot);
                setDisabled(false)
            } else {
                console.log("Document does not exist");
            }
        })
        .catch((error) => {
            console.error("Error updating document:", error);
        });
    }
    return (
      <>
        <Container className="pt-md-2" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="m-0 justify-content-between">
                    <Col xs="12" className="m-0 p-0">
                      <div>
                        <h3>Manage Settings</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h4>
                    Current threshold:{" "}
                    {typeof threshold === "object" ? null : threshold} hr
                  </h4>
                </CardBody>
                <CardBody>
                  <form onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-watch-time"></i>
                        </InputGroupText>

                        <Input
                          type="number"
                          value={newthreshold}
                          placeholder="Enter threshold in hours"
                          className="ml-3"
                          min="1"
                          onChange={(e) => setThreshold(e.target.value)}
                        />
                      </InputGroupAddon>
                    </FormGroup>
                    <Button className="my-4" color="primary" type="submit">
                      Submit
                    </Button>
                  </form>
                </CardBody>
                <CardBody>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      style={{ width: "auto", marginRight: "20px" }}
                      checked={checked}
                      disabled={disabled}
                      onChange={(e) => handleScreenShots(e)}
                    />
                    <Label check>Enable Screenshots..</Label>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
}

ManageSettings.propTypes = {
    threshold: PropTypes.string,
    loading: PropTypes.bool
};

ManageSettings.defaultProps = {
    threshold: '',
    loading: true
}

export default ManageSettings;
