export const validate = (values) => {
  const errors = {};
  if (!values.task) {
    errors.task = "Required";
  }
  if (!values.team) {
    errors.team = "Please select a team";
  }
  if (!values.priority) {
    errors.priority = "Please select priority";
  }
  if (!values.status) {
    errors.status = "Please select status";
  }
  if (!values.assignee) {
    errors.assignee = "Please select an assignee";
  }
  if (!values.summery) {
    errors.summery = "Required";
  } 
  return errors;
};
