/* eslint-disable no-useless-constructor */
import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import ProductiveUnproductiveTime from "./productive-unproductive-time";
import WorkedMostLeastHours from "./worked-most-least-hours";
import IdleMinutes from "./idle-minutes";

class Topusers extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        console.log("TopUsers : ", this.props.topUsers);
        return (
            <>
                <ProductiveUnproductiveTime topUsers={this.props.topUsers} />
                <WorkedMostLeastHours topUsers={this.props.topUsers} />
                <IdleMinutes topUsers={this.props.topUsers} />
            </>
        )
    }
}

const mapStateToProps = state => ({
    days: state.team.days,
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Topusers);
