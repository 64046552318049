import TeamAttendance from '../views/TeamAttendance/team_attendance';
import { connect } from "react-redux";

const mapStateToProps = state => ({
    threshold: state.team.threshold,
    loading: state.team.loading,
    selectedTeamID: state.team.selectedTeamID,
    attendanceData: state.team.attendanceData,
    teamMembers: state.team.teamMembers,
    active: state.team.active
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamAttendance);