import React, { PureComponent } from "react";
import { Card, Row } from "reactstrap";
import { Line } from "react-chartjs-2";
import isUndefined from "lodash/isUndefined";

class HoursTracked extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
    };
  }

  hoursTracked = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };

  trendsHoursTracked() {
    let Monday = this.props?.weekData?.Monday?.totalHours;
    Monday = !isUndefined(Monday) ? Monday : 0;

    let Tuesday = this.props?.weekData?.Tuesday?.totalHours;
    Tuesday = !isUndefined(Tuesday) ? Tuesday : 0;

    let Wednesday = this.props?.weekData?.Wednesday?.totalHours;
    Wednesday = !isUndefined(Wednesday) ? Wednesday : 0;

    let Thursday = this.props?.weekData?.Thursday?.totalHours;
    Thursday = !isUndefined(Thursday) ? Thursday : 0;

    let Friday = this.props?.weekData?.Friday?.totalHours;
    Friday = !isUndefined(Friday) ? Friday : 0;

    let Saturday = this.props?.weekData?.Saturday?.totalHours;
    Saturday = !isUndefined(Saturday) ? Saturday : 0;

    let Sunday = this.props?.weekData?.Sunday?.totalHours;
    Sunday = !isUndefined(Sunday) ? Sunday : 0;
    // ChartJS.register(ArcElement, Tooltip, Legend);
    const data = {
      labels: ["M", "T", "W", "T", "F", "S", "S"],
      datasets: [
        {
          label: "Hours tracked",
          data: [
            Monday.toFixed(2),
            Tuesday.toFixed(2),
            Wednesday.toFixed(2),
            Thursday.toFixed(2),
            Friday.toFixed(2),
            Saturday.toFixed(2),
            Sunday.toFixed(2),
          ],
          fill: false,
          tension: 0,
          borderColor: "rgba(39, 194, 108,1)",
          borderWidth: 4,
          pointBorderColor: "rgba(39, 194, 108,1)",
          pointBackgroundColor: "rgba(255,255,255, 1)",
          pointBorderWidth: 4,
          pointRadius: 5,
        },
      ],
    };
    const options = {
      legend: false,
    };

    console.log("week data", this.props?.weekData);
    return (
      <div>
        <Line data={data} options={options} height="100" />
      </div>
    );
  }
  render() {
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <Card className="shadow p-3">
          <div style={{ minHeight: `${18}vh` }} className="p--1">
            <Row>
              <div className="col">
                <span className="headline">Hours tracked</span>
              </div>
            </Row>

            <Row className="dashboard-section-body">
              <div className="col">{this.trendsHoursTracked()}</div>
            </Row>
          </div>
        </Card>
      </>
    );
  }
}

export default HoursTracked;
