import actions from "../actions/File";

const initialState = {
  loading: false,
  calculatedTimelog: {
    appTimeTrackMap: {},
    screenShots: [],
    totalTimeTracked: ""
  }
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_POC_DATA:
      return { ...state, calculatedTimelog: action.payload };
    case actions.SET_LOADING:
        return { ...state, loading: action.payload};
    default:
      return state;
  }
}
