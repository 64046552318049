import { all, takeEvery, takeLatest, put, call } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "redux-form";
import { push } from "connected-react-router";
import actions from "../actions/Auth";
import auth from "../services/auth";
import {
  addNotification,
  notificationStates,
} from "../components/Notification";
import { resolveSoa } from "dns";
import { isEmpty } from "lodash";
import { currentCompany } from "./../utils/utility";

export function* LOGIN({ payload }) {
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  try {
    yield put(startSubmit("loginForm"));
    const response = yield call(
      auth.signInWithEmail,
      payload.email,
      payload.password
    );
    if (response) {
      yield put({
        type: "user/LOAD_CURRENT_ACCOUNT",
        payload: { action: "signIn" },
      });
      const response = yield call(auth.currentAccount);
      let { success, data } = yield call(
        auth.getCurrentUserDetails,
        response.uid
      );

      if (data.companyList.length === 1) {
        localStorage.setItem("teamData", []);
        yield put({
          type: "team/SWITCH_COMPANY_ACTIVE_TEAM",
          payload: {
            companyId: data.companyList[0].companyId,
            userId: response.uid,
          },
        });
      }
    }
  } catch (error) {
    addNotification({
      message: error.message,
      level: notificationStates.error,
    });
  }
  yield put(stopSubmit("loginForm"));
}

export function* LOAD_CURRENT_ACCOUNT(props) {
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  const response = yield call(auth.currentAccount);
  const cid = currentCompany();
  if (response) {
    const {
      uid: id,
      email,
      emailVerified,
      photoURL: avatar,
      displayName,
    } = response;
    let { success, data } = yield call(auth.getCurrentUserDetails, id);
    if (!emailVerified && props?.payload?.action == "signIn") {
      addNotification({
        message: "Please verify your email address.",
        level: notificationStates.error,
      });
    }
    if (success) {
      yield put({
        type: "user/SET_STATE",
        payload: {
          profile: {
            id,
            name: displayName || email,
            email,
            emailVerified,
            avatar,
            role: !isEmpty(data)
              ? data.companyList &&
                data.companyList.length > 0 &&
                data.companyList[0].role
              : {},
            companyList: !isEmpty(data) ? data?.companyList ?? [] : [],
            activeCompany: !isEmpty(data)
              ? data.companyList && data.companyList.length > 0
                ? !isEmpty(cid)
                  ? data.companyList.filter((i) => i.companyId == cid)[0]
                  : data.companyList[0]
                : {}
              : {},
          },
          authorized: emailVerified ? true : false,
        },
      });
    }
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false,
    },
  });
}

export function* SIGN_UP({ payload }) {
  yield put({
    type: "user/SET_STATE",
    payload: { signUp: { pending: true, success: false, error: false } },
  });
  try {
    const response = yield call(
      auth.signUpWithEmailAndPassword,
      payload.email,
      payload.password
    );
    if (response) {
      // const [verified, created, updated] = yield all([
      //   call(auth.sendVerificationEmail),
      //   call(auth.createUser, {
      //     email: response.user.email,
      //     uid: response.user.uid,
      //     fullName: response.user.displayName,
      //   }),
      //   call(auth.updateProfileName, payload.fullName),
      // ]);
      const verified = yield call(auth.sendVerificationEmail);
      const created = yield call(auth.createUser, {
        email: response.user.email,
        uid: response.user.uid,
        fullName: payload.fullName,
      });
      const updated = yield call(auth.updateProfileName, payload.fullName);
      if (verified && created && updated) {
        yield put({
          type: "user/SET_STATE",
          payload: { signUp: { pending: false, success: true, error: false } },
        });
        yield put({ type: "user/RESET_SIGNUP_STATE" });
        yield put({ type: "user/LOAD_CURRENT_ACCOUNT" });
        addNotification({
          message: "Account created! Please verify your account.",
          level: notificationStates.success,
          autoDismiss: 0,
        });
      }
    }
  } catch (error) {
    yield put({
      type: "user/SET_STATE",
      payload: { signUp: { pending: false, success: false, error: true } },
    });
    yield put({ type: "user/RESET_SIGNUP_STATE" });
    addNotification({
      message: error.message,
      level: notificationStates.error,
    });
  }
}

// export function* CREATE_USER({ payload }) {
//     setAuthState({ signUp: { pending: true, success: false, error: false } })
//     try {
//         const response = yield call(auth.createUser, { ...payload })
//         if (response) {
//             addNotification({
//                 message: "User created in Database",
//                 level: notificationStates.success
//             });
//         }

//     } catch (error) {
//         addNotification({
//             message: error.message,
//             level: notificationStates.error
//         });
//     }
// }

// export function* SEND_VERIFICATION_EMAIL() {
//     setAuthState({ signUp: { pending: true, success: false, error: false } })
//     try {
//         const response = yield call(auth.sendVerificationEmail)
//         if (response) {
//             addNotification({
//                 message: "Check your inbox!",
//                 level: notificationStates.success
//             });
//         }

//     } catch (error) {
//         addNotification({
//             message: error.message,
//             level: notificationStates.error
//         });
//     }
// }

// export function* SIGN_UP({ payload }) {
//     yield put({
//         type: "user/SET_STATE",
//         payload: { signUp: { pending: true, success: false, error: false } }
//     })
//     try {
//         const response = yield call(auth.signUpWithEmailAndPassword, payload.email, payload.password);
//         if (response) {
//             const [verified, updated] = yield all([
//                 call(auth.sendVerificationEmail),
//                 call(auth.updateProfileName, payload.fullName),
//             ])

//             if (verified && updated) {
//                 yield put({
//                     type: "user/SET_STATE",
//                     payload: { signUp: { pending: false, success: true, error: false } }
//                 })
//                 yield put({ type: 'user/RESET_SIGNUP_STATE' })
//                 yield put({ type: 'user/LOAD_CURRENT_ACCOUNT', })
//                 addNotification({
//                     message: "Account created! Please verify your account.",
//                     level: notificationStates.success
//                 });
//             }
//         }
//     } catch (error) {
//         yield put({
//             type: "user/SET_STATE",
//             payload: { signUp: { pending: false, success: false, error: true } }
//         })
//         yield put({ type: 'user/RESET_SIGNUP_STATE' })
//         addNotification({
//             message: error.message,
//             level: notificationStates.error
//         });

//     }
// }

export function* LOGOUT_ACTIVE_ACCOUNT() {
  yield call(auth.signOut);
  localStorage.clear();
  yield put({
    type: "user/SET_STATE",
    payload: {
      authorized: false,
      profile: {},
    },
  });
  yield put({
    type: "team/SET_STATE",
    payload: {
      loading: false,
      teamLoading: false,
      selectedTeamID: null,
      activeUserID: null,
      activeTeam: {},
      teamMembers: [],
      teams: [],
      teamLogs: [],
      active: [],
    },
  });
  yield put(push("/"));
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.SIGN_UP, SIGN_UP),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    // takeEvery(actions.SEND_VERIFICATION_EMAIL, SEND_VERIFICATION_EMAIL),
    // takeEvery(actions.CREATE_USER, CREATE_USER),
    takeLatest(actions.LOGOUT_ACTIVE_ACCOUNT, LOGOUT_ACTIVE_ACCOUNT),
    LOAD_CURRENT_ACCOUNT(),
  ]);
}
