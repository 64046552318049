import { eventChannel } from 'redux-saga';
import { call } from 'redux-saga/effects';
import firebase from '../config';
import appConfig from "../config/constants";
import { AES, enc } from "crypto-js";

const auth = firebase.auth();
const database = firebase.firestore();
let _authChannel = null;

function authChannel() {
    if (_authChannel) return _authChannel;
    const channel = eventChannel((emit) => {
        const unsubscribe = auth.onAuthStateChanged(
            user => emit({ user }),
            error => emit({ error })
        );
        return unsubscribe;
    });

    _authChannel = channel;
    return channel;
}

function* signInWithEmail(email, password) {
  const response = yield call(
    [auth, auth.signInWithEmailAndPassword],
    email,
    password
  );
  if (response) {
    try {
      const cipherText = AES.encrypt(
        "298a5942-0066-11ee-be56-0242ac120002",
        "9a002f5a-7ea6-403f-b2b7-d50401f40fd6"
      );
      response.user["token"] = cipherText.toString();
      database
        .collection("membersDatabase")
        .where("accountEmailid", "==", email)
        .get()
        .then((collections) => {
          const auto = collections.docs.map((res) => res.id);
          try {
            database.collection("membersDatabase").doc(auto[0]).update({
              token: cipherText.toString(),
            });
            localStorage.setItem('userToken', cipherText.toString());
            localStorage.setItem('activeUserId', response?.user?.uid);
          } catch (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  return response;
}

/**
 * SignUpWithEmailAndPassword - Create new user
 * @param {*} email 
 * @param {*} password 
 * @returns 
 */
function* signUpWithEmailAndPassword(email, password) {
    return yield call([auth, auth.createUserWithEmailAndPassword], email, password);
}

/**
 * SendVerificationEmail - Sends verification email
 */
function* sendVerificationEmail() {
    try {
        return yield auth.currentUser.sendEmailVerification({ url: appConfig.firebaseCallbackURL }).then(() => ({ success: true, data: 'Email sent' }));
    }
    catch (e) { return yield ({ success: false, data: 'Email sent' }); }
}

function* updateProfileName(name) {
    try {
        return yield auth.currentUser.updateProfile({ displayName: name }).then(() => ({ success: true, data: 'Profile updated!' }));
    }
    catch (e) { return yield ({ success: false, data: 'Email sent' }); }
}

/**
 * CreateUser - Creates user in `membersDatabase` & in 
 * @param {*} param0 
 * @returns 
 */
async function createUser({ email, uid, fullName }) {
    try {
        // Declare Member data
        const memberInfo = {
            accountEmailid: email,
            accountFullName: fullName,
            desktopLogin: false,
        }
        const batch = database.batch();

        let memberDBRef = database.collection("membersDatabase").doc(uid);

        batch.set(memberDBRef, { ...memberInfo }, { merge: true })

        return batch.commit().then(() => {
            return { success: true, data: 'User created.' };
        });

    } catch (e) {
        return {
            success: false,
            data: e
        }
    }
}


function* signOut() {
    return yield call([auth, auth.signOut]);
}

function* onIdTokenChanged() {
    return yield call([auth, auth.onIdTokenChanged]);
}

function* signInAnonymously() {
    return yield call([auth, auth.signInAnonymously]);
}

function getIdToken(credential) {
    return auth.currentUser
        .getIdToken(credential)
        .then(success => success)
        .catch(error => error);
}

async function currentAccount() {
    let userLoaded = false;
    function getCurrentUser() {
        return new Promise((resolve, reject) => {
            if (userLoaded) {
                resolve(firebase.auth.currentUser);
            }
            const unsubscribe = auth.onAuthStateChanged((user) => {
                userLoaded = true;
                unsubscribe();
                resolve(user);
            }, reject);
        });
    }
    return getCurrentUser();
}

/**
 * getCurrentUserDetails - Gets details of logged in user
 * @param {*} userId 
 * @returns 
 */
async function getCurrentUserDetails(userId) {
    try {
        const query = await database.collection('membersDatabase').doc(userId)
        return query.get().then(async (qSnapshot) => {
            if (qSnapshot.exists) {
                let companyList = []
                await qSnapshot.ref.collection('companyList').get().then((companyLSnapshot) => {
                    if (!companyLSnapshot.empty) {
                        companyLSnapshot.forEach(sCompany => companyList.push(sCompany.data()))
                    }
                })
                return ({ success: true, data: { ...qSnapshot.data(), companyList } })
            } else {
                return ({ success: true, data: {} })
            }
        })
    } catch (e) {
        return ({ success: false, data: e })
    }
}

export default {
    signInWithEmail,
    signInAnonymously,
    signUpWithEmailAndPassword,
    sendVerificationEmail,
    updateProfileName,
    createUser,
    onIdTokenChanged,
    getIdToken,
    signOut,
    authChannel,
    getCurrentUserDetails,
    currentAccount,
};
