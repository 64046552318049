import { START_IMAGE_UPLOAD, IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_FAILURE } from '../../actions/Tasks/taskListActions';

// Initial state
const initialState = {
  isLoading: false,
};

// Reducer function
const imageUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_IMAGE_UPLOAD:
      return {
        ...state,
        isLoading: true,
      };
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default imageUploadReducer;
