/* eslint-disable no-loop-func */

export function CheckPresent(data, teamMembers) {
    let memberId = [];
    let userData = [];
    let dataToSend = [];
    let presentMember = [];
    let presentMembers = [];
    teamMembers.forEach(element => {
        memberId.push(element.userUID)
    });
    data.forEach(element => {
        if (element.success) {
            userData.push(element.data)
        }
        else {
            userData.push(null)
        }
    });
    userData.forEach((element, i) => {
        let temp = 0
        if (element === null) {
            dataToSend.push("-");
            presentMembers.push("-")
        }
        else {
            for (let key of Object.keys(element)) {
                if (memberId.includes(key)) {
                    teamMembers.forEach(member => {
                        if (member.userUID === key) {
                            presentMember.push(member)
                        }
                    });
                    temp = temp + 1;
                }
            }
            presentMembers[i] = presentMember
            presentMember = []
            dataToSend.push(temp)
        }
    })
    return {
        dataToShow: dataToSend,
        presentMembers: presentMembers
    }
}
