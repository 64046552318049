import React, { useCallback, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PrimeButton } from "primereact/button";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "./../../../hooks/userPrevious";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { RiMore2Fill } from "react-icons/ri";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import appConfig from "./../../../config/constants";
import { MultiSelect } from "primereact/multiselect";
import _, { omitBy } from "lodash";
import isEmpty from "lodash/isEmpty";

export default function Members({ handleMemberAction }) {
  const dispatch = useDispatch();
  const teamState = useSelector((state) => ({ ...state.team }));
  const authState = useSelector((state) => ({ ...state.auth }));
  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));
  let prevTeams = usePrevious(teamState?.teams);
  let currentTeams = teamState?.teams;

  let finalizeData = currentTeams?.data?.filter(
    (item, index) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let teamOptions = finalizeData.map((itm) => ({
    label: itm.Team,
    value: itm.activeTeamid,
  }));
  let { pending, successPayload } =
    membersState;
  let members = successPayload ? successPayload.data : [];
  let rolesOptions = [...appConfig.roles].filter((i) => i.value != "Client" && i.value !="Head");
  let [filterBy, setFilter] = useState({
    role: "",
    teams: [],
  });
  let authUserRole = authState?.profile?.role;

  const [searchQuery, setQuery] = useState();
  const [filteredData, setFilteredData] = useState();
  let membersTableData = !_.isEmpty(searchQuery) ? filteredData : members;

  // @@ Debounce Search Query
  const debouncedQuery = useCallback(
    _.debounce((qr) => handleFilter(qr), 700),
    [searchQuery, members]
  );

  /**
   * Life Cycle Hooks
   */

  useEffect(() => {
    //  * Call to get all members from all teams
    if (prevTeams?.data !== currentTeams?.data) {
      if (currentTeams?.data?.length > 0) {
        dispatch({
          type: "teams/GET_MEMBERS",
          payload: { teams: [...finalizeData] },
        });
        let finalizeTeamData = finalizeData.map((itm) => ({
          label: itm.Team,
          value: itm.activeTeamid,
        }));
        if (finalizeTeamData.length > 0) {
          // (finalizeTeamData, 'finalizeTeamDatafinalizeTeamData')
          dispatch({
            type: "teams/GET_TEAMS",
            payload: {
              teams: [...finalizeTeamData],
              authUser: authState?.profile?.id,
              role: authState?.profile?.role,
            },
          });
        }
      }
    }
  }, [teamState]);

  useEffect(() => {
    debouncedQuery(searchQuery);
  }, [searchQuery]);

  /**
   * Common functions
   */

  function handleFilter(sQuery) {
    let result = [];
    if (!_.isEmpty(members) && !_.isEmpty(sQuery)) {
      let smQ = sQuery.toLowerCase();
      result = members.filter(
        (member) =>
          member.userName.toLowerCase().includes(smQ) ||
          member.userEmail.toLowerCase().includes(smQ)
      );
    }
    setFilteredData([...result]);
  }

  function handleApplyFilters(type, value) {
    if (value) {
      setFilter({
        ...filterBy,
        [type]: value,
      });
    }
  }

  function handleFormAction(actionType) {
    setQuery("");
    if (actionType == "reset") {
      resetFilters();
      dispatch({
        type: "teams/GET_MEMBERS",
        payload: { teams: [...finalizeData] },
      });
    } else {
      dispatch({
        type: "teams/GET_MEMBERS",
        payload: {
          teams: [...finalizeData],
          filterBy: omitBy(filterBy, isEmpty),
        },
      });
    }
  }

  // const paginatorLeft = (
  //   <PrimeButton type="button" icon="pi pi-refresh" className="p-button-text" />
  // );
  // const paginatorRight = (
  //   <PrimeButton type="button" icon="pi pi-cloud" className="p-button-text" />
  // );

  function resetFilters() {
    setFilter({
      role: "",
      teams: [],
    });
  }

  useEffect(() => {
    let { role, teams } = filterBy;
    if (role && teams) {
    }
  }, [filterBy]);

  const teamTemplate = (row) => {
    return (
      <>
        <div className="d-flex flex-column">
          {row.teams.map((item) => (
            <div>
              <span>{item.teamName}</span>{" "}
              <span className="font-italic text-muted">({item.role})</span>
            </div>
          ))}
        </div>
      </>
    );
  };

  const actionTemplate = (data) => {
    const managerAc = ["Head", "Admin"].includes(data?.role);
    const headAc = ["Admin"].includes(data?.role);

    let disabledEdit = authState.profile.email == data.userEmail;
    let disabledDelete = authState.profile.email == data.userEmail;

    if (authUserRole == "Head") {
      disabledEdit = disabledEdit || headAc;
      disabledDelete = disabledDelete || headAc;
    }
    if (authUserRole == "Manager") {
      disabledEdit = disabledEdit || managerAc;
      disabledDelete = disabledDelete || managerAc;
    }

    return (
      <UncontrolledButtonDropdown className="">
        <DropdownToggle className="border-0 shadow-none bg-transparent">
          <RiMore2Fill color="primary" size={19} />
        </DropdownToggle>
        <DropdownMenu style={{ boxShadow: "0 2px 6px 0 #d5d0d7" }}>
          <DropdownItem
            disabled={disabledEdit}
            onClick={() => handleMemberAction({ ...data }, "edit")}
          >
            Edit
          </DropdownItem>
          <DropdownItem
            disabled={disabledDelete}
            onClick={() => handleMemberAction({ ...data }, "delete")}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  return (
    <>
      <div className="row d-flex justify-content-between">
        <div className="row col-sm-12 col-lg-12 col-xl-8 d-flex justify-content-between ml-0">
          <Dropdown
            className="p-inputtext-sm col-sm-12 col-md-4 mb-2"
            value={filterBy.role}
            options={rolesOptions}
            onChange={(e) => handleApplyFilters("role", e.value)}
            placeholder="Access Level"
          />

          <MultiSelect
            style={{ height: "44px" }}
            id="teamSelection"
            className="col-sm-12 col-md-4"
            value={filterBy.teams}
            options={teamOptions}
            selectedItemsLabel="{0} teams selected"
            onChange={(e) => handleApplyFilters("teams", e.value)}
            placeholder="Select team"
            maxSelectedLabels={1}
          />

          <div className="filter-buttons col-md-3">
            <Button
              disabled={filterBy.role == "" && filterBy.teams.length == 0}
              onClick={() => handleFormAction("apply")}
              icon="pi pi-check"
              style={{ height: "44px" }}
              className="p-button-rounded p-button-text"
            />
            <Button
              disabled={filterBy.role == "" && filterBy.teams.length == 0}
              onClick={() => handleFormAction("reset")}
              icon="pi pi-times"
              style={{ height: "44px" }}
              className="p-button-rounded p-button-danger p-button-text ml-1"
            />
          </div>
        </div>
        <div className="col-sm-12 col-lg-12 col-xl-4 d-flex justify-content-md-start justify-content-xl-end mb-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm p-inputtext"
              value={searchQuery}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search here..."
            />
          </span>
        </div>
      </div>
      <DataTable
        loading={pending}
        className="bg-white task-table task-page-table"
        value={membersTableData}
        responsiveLayout="scroll"
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={50}
        rowsPerPageOptions={[10, 20, 50]}
        // paginatorLeft={paginatorLeft}
        // paginatorRight={paginatorRight}
      >
        <Column
          headerClassName="text-uppercase"
          className="text-dark text-capitalize td-start"
          field="userName"
          header="Name"
        ></Column>
        <Column
          headerClassName="text-uppercase"
          className="text-dark td-start"
          field="userEmail"
          header="Email"
        ></Column>
        <Column
          headerClassName="text-uppercase"
          className="text-dark td-start"
          field="teams"
          header="Teams"
          body={teamTemplate}
        ></Column>
        <Column
          headerStyle={{ width: "9rem", textAlign: "center" }}
          bodyStyle={{
            width: "9rem",
            textAlign: "center",
            overflow: "visible",
          }}
          body={actionTemplate}
          className="td-start"
        />
      </DataTable>
    </>
  );
}
