import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

function BlankLayout({ children }) {

    useEffect(() => {
        document.body.classList.add("bg-secondary");
        return () => {
            document.body.classList.remove("bg-secondary");
        }
    }, []);

    return (
        <><div className="main-content bg-secondary">
            <div className="py-7 py-lg-5">
                <Container className="pb-5">
                    <Row className="justify-content-center">
                        {children}
                    </Row>
                </Container>
            </div>
        </div>
        </>
    )
}

BlankLayout.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
}

export default BlankLayout;