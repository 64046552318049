import React from "react";
import { Field, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";

const ProjectForm = ({
  handleCloseEventModal,
  handleSubmit,
  submitting,
  project,
}) => {
  const team = useSelector((state) => ({
    teams: state.team.teams,
    activeTeam: state.team.activeTeam,
    selectedTeamId: state.team.active.activeTeamid,
  }));

  const { activeCompany } = useSelector((state) => state.auth.profile);
  const userProfile = useSelector((state) => state.auth.profile);

  const dispatch = useDispatch();

  return (
    <div>
      <form
        onSubmit={handleSubmit((values) => {
          handleCloseEventModal();
          if (project) {
            dispatch({
              type: "team/UPDATE_PROJECT",
              payload: {
                ...values,
                projectId: project.projectId,
                title: values.project,
                companyId: activeCompany.companyId,
              },
            });
          } else {
            dispatch({
              type: "team/ADD_PROJECT",
              payload: {
                ...values,
                companyId: activeCompany.companyId,
                createdBy: userProfile.id,
              },
            });
          }
        })}
      >
        <Field
          type="text"
          name="project"
          className="mb-3 form-control form-control-sm"
          component="input"
          placeholder="Project Title"
        />

        <Button disabled={submitting} color="primary" type="submit" size="sm">
          {project ? `Update Project` : `Create Project`}
        </Button>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "ProjectForm",
})(ProjectForm);
