import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";
import rootSaga from "../sagas";

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middleware = [thunkMiddleware, sagaMiddleware, routeMiddleware];

const enhancer = [];

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancer
);

export default (function configureStore(initialState) {
  const store = createStore(
    rootReducer(history),
    initialState,
    composedEnhancers
  );
  sagaMiddleware.run(rootSaga);
  return { store, history };
})();
