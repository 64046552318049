import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Col, FormFeedback } from "reactstrap";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import {
  FreezeUI,
  UnfreezeUI,
} from "./../../../assets/plugins/nucleo/js/freeze-ui";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { FiChevronRight } from "react-icons/fi";

export default function SelectTeam() {
  const user = useSelector((state) => ({
    userProfile: state.auth?.profile,
    activeCompany: state.auth?.profile?.activeCompany,
    companyList: state.auth?.profile?.companyList,
    activeTeamId: state.team.selectedTeamID,
    activeUserId: state.team.activeUserID,
    all: state.team.all,
  }));
  const teamReducer = useSelector((state) => ({
    loading: state.team.loading,
    activeTeam: state.team.activeTeam,
    activeUserID: state.team.activeUserID,
    members: state.team.teamMembers,
    teams: state.team.teams,
    profileID: state.auth.profile.id,
    all: state.team.all,
    timelogDayRange: state.team.timelogDayRange,
    start: state.team.start,
    end: state.team.end,
    selectedTeamID: state.team.selectedTeamID,
    week: state.team.week,
    topData: state.team.topData,
    active: state.team.active,
    projects: state.team.projects,
    selectedProject: state.team.selectedProject,
    selectTeams: state.team.selectTeams,
  }));
  const history = useHistory();
  const authState = useSelector((state) => ({ ...state.auth }));
  const isUserAuthorized = authState.authorized;
  const [selectedTeam, setTeam] = useState();
  const rSelected = 0;
  // const teams = useSelector((state) => [...state.team?.teams?.data]);
  const [error, setError] = useState({ isError: false, msg: "" });
  const [teamName, setTeamName] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    // Redirect unAuthorized users to home page
    if (!authState.loading && !isUserAuthorized) {
      history.push("/");
    }
    // if (authState.loading) FreezeUI();
    if (!authState.loading) UnfreezeUI();
  }, [authState]);

  const itemTemplateTeam = (option) => {
    return (
      <div
        className="d-flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          textTransform: "capitalize",
        }}
        onClick={() => setTeamName(option?.Team)}
      >
        <div className="font-weight-bold">{option.Team}</div>
        <div>
          <FiChevronRight size={21} />
        </div>
      </div>
    );
  };

  const handleNavigation = () => {
    if (!selectedTeam) {
      setError({
        isError: true,
        msg: "Please select at least one of the companies.",
      });
    } else {
      let obj = { Team: teamName, activeTeamid: selectedTeam };
      localStorage.setItem("teamData", JSON.stringify(obj));
      try {
        dispatch({
          type: "team/SET_SELECT_TEAM_ID",
          payload: {
            selectedTeamID: selectedTeam,
            activeUserID: user.userProfile?.id,
            activeTeam: {
              Team: teamName,
              activeTeamid: selectedTeam,
            },
            companyId: user.activeCompany.companyId,
          },
        });
      } catch (error) {
        return error;
      }
      setTimeLogDays(rSelected, selectedTeam);
      window.location = "/dashboard-individual";
    }
  };

  const setTimeLogDays = (days, id, start, end) => {
    dispatch({
      type: "team/SET_TIMELOG_DAY_RANGE",
      payload: { days, start, end },
    });

    dispatch({
      type: "member/SET_TIMELOG_DAY_RANGE",
      payload: { days, start, end },
    });

    dispatch({
      type: "team/SET_TODAY_TIMES",
      payload: {
        selectedTeamID: id,
        day: days,
      },
    });
    dispatch({
      type: "team/SET_TRENDS_DATA",
      payload: {
        selectedTeamID: id,
        week: teamReducer.week,
      },
    });
    // dispatch({
    //   type: "team/SET_TOP_USERS",
    //   payload: {
    //     selectedTeamID: id,
    //     day: days,
    //   },
    // });

    if (user.all) {
      dispatch({
        type: "team/GET_ALL_TEAM_TIMELOG",
        payload: {
          days,
          start,
          end,
          teamId: id,
          projectId: teamReducer.selectedProject,
        },
      });
    } else {
      dispatch({
        type: "team/GET_MANAGER_TEAM_COLLECTION",
        payload: {
          days,
          start,
          end,
          activeTeamId: id,
          activeUserId: user.activeUserId,
        },
      });
    }
  };
  console.log("Team :", teamReducer.selectTeams);
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary border-0">
          <CardHeader className="bg-transparent p-0 m-0 border-0">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>Select a Team</h2>
              <small>
                Select any team to navigate to the relevant dashboard111111.
              </small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-4">
            <SelectButton
              optionLabel="Team"
              optionValue="activeTeamid"
              className="company_select-btn"
              itemTemplate={itemTemplateTeam}
              options={teamReducer?.selectTeams?.data}
              value={selectedTeam}
              onChange={(e) => setTeam(e.value)}
            ></SelectButton>
            {error.isError && (
              <FormFeedback className="d-block text-center">
                {error.msg}
              </FormFeedback>
            )}
            <div className="w-100 text-center mt-3">
              <Button
                type="button"
                onClick={() => handleNavigation()}
                label="Navigate"
                className="p-button p-button-secondary"
                icon="pi pi-arrow-right"
                iconPos="right"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
