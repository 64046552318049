import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import React, { useCallback } from "react"
import { Row } from "reactstrap"

const ProjectTable = ({data = [], projects, type}) => {
 const sortedData= data.map(entry => {
    let totaltime = entry.hours*60 + entry.minuites
    return {...entry,totalTime:totaltime}
  });

  sortedData.sort((a, b) => b.totalTime - a.totalTime);

  let updatedData = sortedData.map((d) => {
    return {
      projectName: d.projectName,
      time: `${d.hours}h ${d.minuites}m`
    }
  })

  const fieldIndex = (rowData, column) => {
    return column.rowIndex + 1 + "";
  };

  const projectName = useCallback((rowData) => {
    if (!projects) return null;
    const project = projects.find((item) => item.id === rowData.projectName);
    return project ? project.data.project_name : null;
}, [projects]);

  return (
    <>
    {updatedData.length > 0 && projects.length > 0 &&  (
    <>
      {type !== "card" && <div className="header-body">
        <Row>
          <div className="col dashboard-section-heading">
            <h3>Total Time Spent on Projects</h3>
          </div>
        </Row>
      </div>}
      <DataTable
        // responsiveLayout="scroll"
        className={`task-table task-page-table timelog-card-table`}
        value={updatedData}
        // paginator
        // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        style={{ cursor: "pointer", borderRadius: "6px", overflow: "hidden" }}
        rows={50}
        // rowsPerPageOptions={[10, 20, 50]}
      >
        <Column
          field="#"
          header="ID"
          body={fieldIndex}
          className="first-col td-start"
        ></Column>
        <Column
          field="projectName"
          header="Title"
          body={projectName}
          className={`${type === "card" ? "second-col-card td-start elipsis" : "second-col td-start elipsis"}`}
        ></Column>

        <Column
          field="time"
          header="Hours"
          className="first-col td-start"
        ></Column>
      </DataTable>
    </>
    )}
    </>
  );
}

export default ProjectTable