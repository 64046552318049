import { combineReducers } from 'redux';
import sendInviteReducer from './invite';
import getMembersReducer from './members/index';
import getTeamsReducer from './teams/index';

const manageTeamReducer = combineReducers({
    inviteTeam: sendInviteReducer,
    members: getMembersReducer,
    teams: getTeamsReducer
})

export default manageTeamReducer