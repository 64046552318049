import { eventChannel } from "redux-saga";
import { call } from "redux-saga/effects";
import firebase from "../config";

const auth = firebase.auth();
const database = firebase.firestore();
let _authChannel = null;

function getInvitationDetails(id) {
  try {
    const docRef = database.collection("userInvites").doc(id);
    return docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return {
            data: doc.data(),
            success: true,
          };
        } else {
          return {
            success: false,
            data: "Oops! I did not found any invitation for you.",
          };
        }
      })
      .catch((e) => {
        return {
          success: false,
          data: e,
        };
      });
  } catch (e) {
    return {
      success: false,
      data: e,
    };
  }
}

function acceptInvitation(payload) {
  const { invitationId, teamDetails, companyDetails, userDetails } = payload;

  try {
    let batch = database.batch();
    const userInviteRef = database.collection("userInvites").doc(invitationId);
    const memberDBRef = database
      .collection("membersDatabase")
      .doc(userDetails.userId);
    const memberCompanyListRef = memberDBRef
      .collection("companyList")
      .doc(companyDetails.companyId);
    const companyDBRef = database
      .collection("companyDatabase")
      .doc(companyDetails.companyId);

    // **************** Common Database entries **************** //

    // Updates to the userInvites database
    batch.update(userInviteRef, { accepted: true });

    // ** Add company details to companyList
    batch.set(
      memberCompanyListRef,
      {
        role: userDetails.invitedAs,
        companyName: companyDetails.companyName,
        companyId: companyDetails.companyId,
      },
      { merge: true }
    );

    // ********************************************************* //

    // Admin
    if (userDetails.invitedAs == "Admin") {
      // Add admin to memberDatabase table with companyList
      batch.set(
        memberDBRef,
        {
          accountEmailid: userDetails.userEmail,
          accountFullName: userDetails.userName,
          desktopLogin: false,
        },
        { merge: true }
      );

      // Adds teamList to company of companyList
      if (Array.isArray(teamDetails)) {
        teamDetails.map((tm) => {
          const memberTeamListRef = memberCompanyListRef
            .collection("teamList")
            .doc(tm.teamId);
          batch.set(
            memberTeamListRef,
            {
              Role: userDetails.invitedAs,
              Team: tm.teamName,
              email: userDetails.userEmail,
            },
            { merge: true }
          );

        //   const memberTeamList = memberDBRef
        //     .collection("teamList")
        //     .doc(tm.teamId);
        //   batch.set(
        //     memberTeamList,
        //     {
        //       Role: userDetails.invitedAs,
        //       Team: tm.teamName,
        //       email: userDetails.userEmail,
        //     },
        //     { merge: true }
        //   );

          // Updates to the teamDatabase
          const teamRef = database.collection("teamDatabase").doc(tm.teamId);
          batch.update(teamRef, {
            [`memberDetails.${userDetails.userId}`]: {
              userEmailid: userDetails.userEmail,
              userName: userDetails.userName,
              userType: userDetails.invitedAs,
            },
          });
        });
      }

      // Updates to companyDatabase
      batch.set(
        companyDBRef,
        {
          admins: firebase.firestore.FieldValue.arrayUnion(userDetails.userId),
        },
        { merge: true }
      );
    } else {
      const teamRef = database
        .collection("teamDatabase")
        .doc(teamDetails.teamId);
    //   const memberRef = memberDBRef
    //     .collection("teamList")
    //     .doc(teamDetails.teamId);
      const memberTeamListRef = memberCompanyListRef
        .collection("teamList")
        .doc(teamDetails.teamId);
      const companyTeamsRef = companyDBRef
        .collection("teams")
        .doc(teamDetails.teamId);

      // Updates to the teamDatabase
      batch.update(teamRef, {
        [`memberDetails.${userDetails.userId}`]: {
          userEmailid: userDetails.userEmail,
          userName: userDetails.userName,
          userType: userDetails.invitedAs,
        },
      });

      // ** Set active team id
    //   batch.set(
    //     memberDBRef,
    //     {
    //       activeTeamid: teamDetails.teamId,
    //     },
    //     { merge: true }
    //   );

      // // ** Add company details to companyList
      // batch.set(memberCompanyListRef, {
      //     role: userDetails.invitedAs,
      //     companyName: companyDetails.companyName,
      //     companyId: companyDetails.companyId
      // }, { merge: true });

      // **  Updates in teamList of member
    //   batch.set(
    //     memberRef,
    //     {
    //       Role: userDetails.invitedAs,
    //       Team: teamDetails.teamName,
    //       email: userDetails.userEmail,
    //     },
    //     { merge: true }
    //   );

      // Add team to teamList in related CompanyList
      batch.set(
        memberTeamListRef,
        {
          Role: userDetails.invitedAs,
          Team: teamDetails.teamName,
          email: userDetails.userEmail,
        },
        { merge: true }
      );

      // ** Updates to the companyDatabase records
      if (userDetails.invitedAs != "Admin") {
        batch.set(
          companyTeamsRef,
          {
            members: firebase.firestore.FieldValue.arrayUnion(
              userDetails.userId
            ),
          },
          { merge: true }
        );
      }
    }

    // Executes batch operations
    return batch
      .commit()
      .then(() => {
        return {
          data: "Invite accepted successfully",
          success: true,
        };
      })
      .catch((e) => {
        return { success: false, data: e };
      });
  } catch (e) {
    return { success: false, data: e };
  }
}

export default {
  getInvitationDetails,
  acceptInvitation,
};
