import React, { useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map'
import Gallery from 'react-grid-gallery';
import Modal from "../../components/Modal";
const Screenshots = ({ isOpen, onClose, images }) => {
    const selectedImages = map(images, (image) => {
        return {
            src: image,
            thumbnail: image,
            thumbnailWidth: 320,
            thumbnailHeight: 212
        }
    })
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Screenshots"
            size="lg"
        >
            <Gallery
                enableImageSelection={false}
                images={selectedImages}
                showLightboxThumbnails
                lightBoxProps={{
                    isFullscreen: true
                }}
            />
        </Modal>
    )
};

Screenshots.propTypes = {

};

export default Screenshots;
