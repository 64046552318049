import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import firebase from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {
  addNotification,
  notificationStates,
} from "../../components/Notification";

// custom  imports
import "./taskpage.css";
import { Button, Spinner } from "reactstrap";
import { startCase } from "lodash";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FirebaseImageUploadPlugin } from "./UploadPlugin";
import { useHistory } from "react-router-dom";

const AddUpdateTaskPage = () => {
  const database = firebase.firestore();
  const dispatch = useDispatch();
  const history = useHistory()
  const paramsData = useParams();
  const taskId = paramsData.taskId || null
  const pName = paramsData?.projectName?.split("-").join(" ") || null
  const user = useSelector((state) => ({
    activeCompany: state.auth?.profile?.activeCompany,
  }));
  const activeUserProfile = useSelector((state) => ({
    companyList: state.auth?.profile?.companyList,
    profile: state?.auth?.profile,
  }));
  const {profile }= activeUserProfile;
  const {activeCompany }= user;

  const [comments, setComments] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedComment, setEditedComment] = useState("");

  const handleEditComment = (commentId, initialComment) => {
    setEditingCommentId(commentId);
    setEditedComment(initialComment);
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditedComment("");
  };

  const handleUpdateComment = async (commentId) => {
    try {
      // Check if the edited comment is empty
      if (editedComment.trim() === "") {
        window.confirm("Comment cannot be empty!")
        console.log("Comment cannot be empty!");
        return;
      }

      await database
        .collection("taskDatabase")
        .doc(taskId)
        .collection("comments")
        .doc(commentId)
        .update({ comment: editedComment });

      setEditingCommentId(null);
      setEditedComment("");

      // Fetch comments again after editing
      fetchCommentsFromFirebase(taskId).then((commentsData) => {
        setComments(commentsData);
      });

      console.log("Comment updated successfully!");
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      try {
        await database
          .collection("taskDatabase")
          .doc(taskId)
          .collection("comments")
          .doc(commentId)
          .update({ deleted: true });
  
        // Fetch comments again after updating
        fetchCommentsFromFirebase(taskId).then((commentsData) => {
          setComments(commentsData);
        });
  
        console.log("Comment marked as deleted successfully!");
      } catch (error) {
        console.error("Error marking comment as deleted:", error);
      }
    }
  };
  
  const addCommentToFirebase = async (taskId, timestamp, commentData) => {
    try {
      const commentWithDeleted = { ...commentData, deleted: false };
      await database
        .collection("taskDatabase")
        .doc(taskId)
        .collection("comments")
        .doc(timestamp)
        .set(commentWithDeleted);
      console.log("Comment added successfully to Firebase!");
      return true
    } catch (error) {
      console.error("Error adding comment to Firebase:", error);
      throw error;
    }
  };
  const fetchCommentsFromFirebase = async (taskId) => {
    try {
      const commentsSnapshot = await database
        .collection("taskDatabase")
        .doc(taskId)
        .collection("comments")
        .where("deleted", "==", false)
        .get();
      const commentsData = commentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return commentsData;
    } catch (error) {
      console.error("Error fetching comments from Firebase:", error);
      return [];
    }
  };
  
  useEffect(() => {
    if (taskId) {
      fetchCommentsFromFirebase(taskId).then((commentsData) => {
        setComments(commentsData);
      });
    }
  }, [taskId]);

  const handleAddComment = () => {
    if (newComment.trim() === "") {
      // Show a message indicating that the comment cannot be empty
      window.confirm("Comment cannot be empty!")
      console.log("Comment cannot be empty!");
      return;
    }
    const timestamp = new Date().getTime().toString();
    const userId = profile?.id;
    // const strippedNewComment = stripHtmlTags(newComment);
    setNewComment("");
    setShowCommentBox(false);
    addCommentToFirebase(taskId, timestamp, { userId, comment: newComment }).then((result) => {
      if(result){
        fetchCommentsFromFirebase(taskId).then((commentsData) => {
          setComments(commentsData);
        });
      }
    });
  };
  
  
  const [projectId, setProjectId] = useState();
  useEffect(() => {
    console.log("something")
    const fetchProjectId = async () => {
      const projectsRef = database
        .collection("companyDatabase")
        .doc(activeCompany.companyId)
        .collection('projects');
      const querySnapshot = await projectsRef.where('project_name', '==', pName).get();
      querySnapshot.forEach((doc) => {
        let newData = doc.data()
        if(newData.deleted === true) return
        setProjectId(doc.id)
        callTaskList(doc.id);
      })
    }
    fetchProjectId();
  },[pName])
  
  const imageUpload = useSelector((state) => ({
    imageUpload: state.taskImage.isLoading
  }));

  const teams = useSelector((state) => ({
    teams: state.team.teams,
    loadingProject: state.team.loadingProject,
    activeTeam: state.team.activeTeam,
    selectedTeamId: state.team.selectedTeamID,
    projects: state.team.projects,
    tasks: state.team.tasks,
    activeUserId: state.team.activeUserID,
    all: state.team.all,
    loading: false,
    selectedProject: state.team.selectedProject,
    active: state.team.active,
    membersData: state.team.membersData,
  }));

  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));

  let currentTeams = teams?.teams;
  let finalizeData = currentTeams.data.filter(
    (item) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let { successPayload } = membersState;
  let members = successPayload ? successPayload.data : [];
  const [selectedDate, setSelectedDate] = useState(new Date());

  React.useEffect(() => {
    if (currentTeams?.data?.length > 0) {
      dispatch({
        type: "teams/GET_MEMBERS",
        payload: { teams: [...finalizeData] },
      });
    }
  }, [teams.selectedTeamId]);

  const [input, setInput] = React.useState({
    title: "",
    description: "",
    assignee: "",
    status: "",
    priority: "",
    date: new Date(),
  });
  const teamData = [];
  if(teams.projects.length > 0){
    teams.projects.map((item) => {
      if (!item.data.deleted) {
        const obj = {
          name: item.data.project_name,
          id: item.id,
        };
        teamData.push(obj);
      }
    });
  }

  const newTeamData = teamData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const [options, setOptions] = React.useState([]);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [successData, setSuccessData] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState(false);
  const [errorData, setErrorData] = React.useState(false);

  const [task, setTask] = React.useState([]);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const pad2 = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const handleSubmit = async () => {
    if (
      !input.title ||
      !input.description ||
      !input.status ||
      !input.priority ||
      !input.assignee
    ) {
      const requiredField = [
        "title",
        "description",
        "assignee",
        "status",
        "priority",
      ];
      for (let i = 0; i < requiredField.length; i++) {
        let field = requiredField[i];
        if (!input[field]) {
          field = field === "description" ? " Summary" : startCase(field);
          addNotification({
            message: `${field} is required`,
            level: notificationStates.error,
          });
          return false;
        }
      }
    }
    setSubmitLoading(true);
   
    if(taskId && projectId){
      let dateId = "";
      if (input.date && typeof input?.date === "string") {
        dateId = input?.date;
      } else if (typeof input.date === "object") {
        var month = pad2(input?.date?.getMonth() + 1);
        var day = pad2(input?.date?.getDate());
        var year = input?.date?.getFullYear();
        dateId = day + "/" + month + "/" + year;
      }
      await database
        .collection("taskDatabase")
        .doc(taskId)
        .update({
          Description: input.description,
          Assignee: input.assignee,
          Priority: input.priority,
          Status: input.status,
          Title: input.title,
          ETA: dateId,
        })
        .then((data) => {
          setSuccessData(true);
          setSubmitLoading(false);
          addNotification({
            message: "Task updated successfully",
            level: notificationStates.success,
          });

        })
        .catch((error) => {
          setSubmitLoading(false);
          addNotification({
            message: "Task update failed",
            level: notificationStates.error,
          });
          throw error;
        });
    }
    else   {
      var month = pad2(input.date.getMonth() + 1);
      var day = pad2(input.date.getDate());
      var year = input.date.getFullYear();
      let dateId = day + "/" + month + "/" + year;
      let taskId = ""

      database
        .runTransaction(async (transaction) => {
          const cityRef = database
            .collection("companyDatabase")
            .doc(activeCompany.companyId)
            .collection("projects")
            .doc(input.team);
          const doc = await transaction.get(cityRef);

          const project = doc.data();
          const newTaskSplitd = project.project_name.split(" ")
          const firstLater =
            newTaskSplitd.length === 1
              ? `${newTaskSplitd[0][0]}${newTaskSplitd[0][1]}`
              : `${newTaskSplitd[0][0]}${newTaskSplitd[1][0]}`;
          const noOfTask = project?.taskList?.length || 0
          taskId = `${firstLater.toUpperCase()}-${noOfTask + 1}`
          const taskRef = database.collection("taskDatabase").doc(taskId);
          const arr = [];
          arr.push(taskId);
          if (project.taskList) {
            project.taskList.map((item) => arr.push(item));
            const newA = project.taskList.filter(
              (item) => item.toLowerCase() === input.title.toLowerCase()
            );
            if (newA.length === 0) {
              const cityRef = database
                .collection("companyDatabase")
                .doc(activeCompany.companyId)
                .collection("projects")
                .doc(input.team);
              await transaction.update(cityRef, { taskList: arr });
            }
          } else {
            const cityRef = database
              .collection("companyDatabase")
              .doc(activeCompany.companyId)
              .collection("projects")
              .doc(input.team);
            await transaction.update(cityRef, { taskList: [taskId] });
          }
          transaction.set(taskRef, {
            Title: input.title,
            Description: input.description,
            Priority: input.priority,
            Assignee: input.assignee,
            Status: input.status,
            ProjectID: input.team,
            ETA: dateId,
          });
        })
        .then(async (data) => {
          setInput({
            ...input,
            team: "",
            title: "",
            description: "",
            assignee: "",
            status: "",
            priority: "",
            date: new Date(),
          });
          setSuccessData(true);
          setSubmitLoading(false);
          addNotification({
            message: "Task submitted successfully",
            level: notificationStates.success,
          });
          const pName = input.projectLabel.split(" ").join("-")
          history.push(`/${pName}/${taskId}`);
        })
        .catch((error) => {
          addNotification({
            message: "Task submission failed",
            level: notificationStates.error,
          });
        });
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const tb = database.collection("taskDatabase").doc(taskId);
    const cb = database
      .collection("companyDatabase")
      .doc(user?.activeCompany?.companyId)
      .collection("projects")
      .doc(projectId);
    return database
      .runTransaction(async (transaction) => {
        await transaction.update(tb, {
          Description: input.description,
          Assignee: input.assignee,
          Priority: input.priority,
          Status: input.status,
          Title: input.title,
          Deleted: true,
        });
        await transaction.update(cb, {
          taskList: firebase.firestore.FieldValue.arrayRemove(taskId),
        });
      })

      .then((data) => {
        addNotification({
          message: "Task deleted successfully",
          level: notificationStates.success,
        });
        setDeleteData(true);
        setDeleteLoading(false);
        setTimeout(() => {
          window.location = "/manage-tasks";
        }, 400);
      })
      .catch((error) => {
        setDeleteLoading(false);
        addNotification({
          message: "Task deletion failed",
          level: notificationStates.error,
        });
        throw error;
      });
  };

  const callTaskList = async (pID) => {
    const taskData = await database
      .collection("taskDatabase")
      .where("ProjectID", "==", pID)
      .get();
    let lists = taskData?.docs.map((res) => res.data());
    let listId = taskData?.docs?.map((r) => r?.id);
    let listsWithId = [];
    for (let i = 0; i < lists.length; i++) {
      listsWithId.push({ ...lists[i], id: listId[i] });
    }
    const taskItem = listsWithId.filter((item) => item.id === taskId);

    if (taskItem) {
      var datearray = taskItem[0]?.ETA.split("/");
      var newdate = datearray[1] + "/" + datearray[0] + "/" + datearray[2];
      setSelectedDate(new Date(newdate));
      setInput({
        title: taskItem[0]?.Title,
        description: taskItem[0]?.Description,
        status: taskItem[0]?.Status,
        priority: taskItem[0]?.Priority,
        date: taskItem[0]?.ETA,
      });
      setTask(taskItem);
    }
  };

  // const stripHtmlTags = (html) => {
  //   const tempElement = document.createElement("div");
  //   tempElement.innerHTML = html;
  //   return tempElement.textContent || tempElement.innerText || "";
  // };  

  React.useEffect(() => {
    if (members) {
      setOptions(
        members?.map((member) => ({
          value: member.userUID,
          label: member.userName,
        }))
      );
    }
  }, [members]);

  const selectedUser = useMemo(
    () => {
      const value = input.assignee || task[0]?.Assignee;
      return options?.find((item) => item.value === value)
    },
    [options, task[0]?.Assignee, input.assignee]
  );

  React.useEffect(() => {
    if (selectedUser) {
      setInput({ ...input, assignee: selectedUser.value });
    }
  }, [selectedUser ]);

  React.useEffect(() => {
    setTimeout(() => {
      setSuccessData(false);
      setErrorData(false);
      setDeleteData(false);
    }, 1500);
  }, [successData, errorData, deleteData]);

  const dispatchRef = useRef();

  useEffect(() => {
    dispatchRef.current = dispatch;
  }, [dispatch]);

  const FileUploadPlugin = useCallback(function (editor) {
    if (!taskId) {
      return FirebaseImageUploadPlugin(editor, input.title, dispatchRef);
    }
    return FirebaseImageUploadPlugin(editor, taskId, dispatchRef);
  }, [taskId,input, dispatchRef]);
      
  const actionName = taskId ? 'Update':'Create task'

  return (
    <>
      <div className="task px-3">
        <div className="taskbox">
          <div className="taskboxone">
          {taskId ?  
            <div className="title">
              <p className="mb-0">Project name: { pName}</p>
            </div> :
            <div className="title">
              <p>Project</p>
              <Select
                options={newTeamData}
                defaultValue={""}
                placeholder="Select Project"
                onChange={(option) =>{
                  setInput({ ...input, team: option.value, projectLabel: option.label })}
                }
                name="assignee"
              />
            </div>}
            <div className="title">
              <label htmlFor="titleField">Title</label>
              <input
                id="titleField"
                className="inputfield"
                value={input.title}
                name="title"
                onChange={onChange}
              />
            </div>
            <div className="">
              <label htmlFor="summaryField">Summary</label>
              <CKEditor
                  className="ckEditor"
                  editor={ ClassicEditor }
                  data={input.description}
                  config={{
                    extraPlugins: [FileUploadPlugin]
                  }}
                  onChange={ ( _, editor ) => {
                    const value = editor.getData();
                    setInput((prev)=>({ ...prev, description: value }));
                } }
              />
            </div>
          </div>
          <div className="detailbox">
            <h2>Details</h2>
            <hr />
            <div className="details">
              <div>
                <p>Assignee</p>
                 {options.length > 0 ?  <Select
                    options={options}
                    defaultValue={selectedUser || null}
                    value={selectedUser}
                    placeholder="Select assignee"
                    onChange={(option) =>
                      setInput((prev)=>({ ...prev, assignee: option.value }))
                    }
                    name="assignee"
                /> : 
                <span>
                <Spinner size="sm" /> Loading...
              </span>}
              </div>
              <div>
                <p>Status</p>
                <select
                  className="selectionStatus"
                  onChange={onChange}
                  value={input.status}
                  name="status"
                >
                  <option value="" disabled="disabled">
                    Status
                  </option>
                  <option value="TO DO">TO DO</option>
                  <option value="IN PROGRESS">IN PROGRESS</option>
                  <option value="QA">QA</option>
                  <option value="DONE">DONE</option>
                </select>
              </div>
              <div>
                <p>Priority</p>
                <select
                  className="taskasigneeStatus"
                  onChange={onChange}
                  value={input.priority}
                  name="priority"
                >
                  <option value="" disabled="disabled">
                    Priority
                  </option>
                  <option value="P1">P1</option>
                  <option value="P2">P2</option>
                  <option value="P3">P3</option>
                  <option value="P4">P4</option>
                </select>
              </div>
              <div className="select-date-item">
                <p>ETA</p>
                <DatePicker
                  selected={selectedDate === "" ? input.eta : selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setInput({
                      ...input,
                      date,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Button color="primary" className="updateTaskButton" onClick={handleSubmit} disabled={imageUpload.imageUpload  || !options.length > 0}>
          {submitLoading ? (
            <span>
              <Spinner size="sm" /> {actionName}
            </span>
          ) : (
            actionName
          )}
        </Button>
       {taskId && <Button color="danger" onClick={handleDelete} disabled={!options.length > 0}>
          {deleteLoading ? (
            <span>
              <Spinner size="sm" /> Delete
            </span>
          ) : (
            "Delete"
          )}
        </Button>}
        {options?.length > 1 && taskId && <div className="comments comment-section mt-4">
          <h3>Comments</h3>
          <div className="comment-list">
            {comments.length > 0 &&
              Array.isArray(comments) &&
              comments
                .sort((a, b) => parseInt(b.id) - parseInt(a.id))
                .map((comment, index) => {
                  
                  const commenter = members && members.find((member) => member.userUID === comment.userId);
                  const userName = commenter ? commenter.userName : "Unknown User";
                  const isEditing = editingCommentId === comment.id;
                  const hasImage = /<img[^>]+src="([^">]+)"/.test(comment.comment);

                  return (
                    <div key={index} className="comment comment-item">
                      <div className="comment-header">
                        <p className="username">{userName}</p>
                      </div>
                      <div className="comment-body">
                        {isEditing ? (
                          <CKEditor
                            className="ckEditor"
                            editor={ ClassicEditor }
                            data={editedComment}
                            config={{
                              extraPlugins: [FileUploadPlugin]
                            }}
                            onChange={ ( _, editor ) => {
                              const value = editor.getData();
                              setEditedComment(value);
                            } }
                          />
                        ) : (
                          // strippedComment
                          <div 
                            dangerouslySetInnerHTML={{ __html: comment.comment }} 
                            className={hasImage ? 'comment-image' : ''} 
                          />
                        )}
                      </div>
                      {/* Edit and delete buttons */}
                      <div className="save-edit-btn">
                          {isEditing ? (
                            <div className="comment-buttons">
                              <span disabled={imageUpload.imageUpload  || !options.length > 0} className="edit-comment" onClick={() => handleUpdateComment(comment.id)}>Save</span>
                              <span className="delete-comment" onClick={() => handleCancelEdit()}>Cancel</span>
                            </div>
                          ) : (
                            <div className="comment-buttons">
                              <span className="edit-comment" onClick={() => handleEditComment(comment.id, comment.comment)}>Edit</span>
                              <span className="delete-comment" onClick={() => handleDeleteComment(comment.id)}>Delete</span>
                            </div>
                          )}
                        </div>
                    </div>
                  );
                })}
          </div>

          {!showCommentBox && <Button color="primary" disabled={editingCommentId !== null} className="updateTaskButton" onClick={() => setShowCommentBox(true)}>
          {!showCommentBox ? (
            <span>
              Comment
            </span>
          ) :<></>}
        </Button>}

          {showCommentBox && (
          <>
            <div className="commentBox mb-4">
             
              <div className="">
                <CKEditor
                  className="ckEditor"
                  editor={ ClassicEditor }
                  config={{
                    extraPlugins: [FileUploadPlugin]
                  }}
                  value={newComment}
                  onChange={ ( _, editor ) => {
                    const value = editor.getData();
                    setNewComment(value);
                  } }
                  placeholder="Add a comment..."
                />
              </div>
            </div>
            <Button color="primary" disabled={imageUpload.imageUpload  || !options.length > 0 || newComment.trim() === ""} className="updateTaskButton" onClick={handleAddComment}>
              Submit
            </Button>
            <Button color="danger" className="updateTaskButton" onClick={() => setShowCommentBox(false)}>
              Cancel
            </Button>
          </>
          )}
        </div>}
      </div>
    </>
  );
};

export default AddUpdateTaskPage;
