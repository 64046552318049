import ManageSettings from '../views/Settings/manage_settings';
import { connect } from "react-redux";

const mapStateToProps = state => ({
    threshold: state.team.threshold,
    loading: state.team.loading
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSettings);