const actions = {
  SET_STATE: "user/SET_STATE",
  LOGIN: "user/LOGIN",
  SIGN_UP: "user/SIGN_UP",
  FORGOT_PASSWORD: "user/FORGOT_PASSWORD",
  // SEND_VERIFICATION_EMAIL: "user/SEND_VERIFICATION_EMAIL",
  // CREATE_USER: "user/CREATE_USER",
  RESET_PASSWORD: "user/RESET_PASSWORD",
  RESET_SIGNUP_STATE: "user/RESET_SIGNUP_STATE",
  LOAD_CURRENT_ACCOUNT: "user/LOAD_CURRENT_ACCOUNT",
  LOGOUT_ACTIVE_ACCOUNT: "user/LOGOUT_ACTIVE_ACCOUNT",
  LOGOUT: "user/LOGOUT"
};

export default actions;
