import React, { useEffect } from "react"
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup"
import { errorRenderer } from './../../../utils/utility';
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from './../../../hooks/userPrevious';
import { useHistory } from 'react-router-dom';

function SignUpForm() {

    /**
     * Form schemas for validation
     */

    const FormSchema = Yup.object().shape({
        full_name: Yup.string().required("Full Name is required").min(3, "Name must contain 3 letters"),
        email: Yup.string().email('Email must be a valid email').required('Email address is required'),
        password: Yup.string().required('Password is required')
            .matches(
                /^(?=.*[!@#$&*])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{6,}$/,
                "At least 6 Characters, At least one uppercase, one number and one special case character"
            ),
        confirm_password: Yup.string().required('Please confirm your password').oneOf([Yup.ref('password'), null], 'Passwords must match.'),
        policy: Yup.bool().oneOf([true], 'Please accept the terms and conditions.'),
    });


    /**
     * Declarations
     */
    const { register, handleSubmit, reset, trigger, formState: { errors } } = useForm({ resolver: yupResolver(FormSchema) });
    const dispatch = useDispatch()
    const history = useHistory();
    const signUpAuth = useSelector((state) => ({ ...state.auth.signUp }))


    /**
     * handleSignUpSubmit - handles user sign up process
     * @param {*} data - SignUp Form Data 
     */

    const handleSignUpSubmit = (data) => {

        dispatch({
            type: "user/SIGN_UP",
            payload: {
                email: data.email,
                password: data.password,
                fullName: data.full_name
            }
        })

    }

    useEffect(() => {
        if (signUpAuth.error || signUpAuth.success) {
            reset();
            history.push('/auth/login');
        }
    }, [signUpAuth])

    return (
        <Form role="form" onSubmit={handleSubmit(handleSignUpSubmit)}>
            <FormGroup className='mb-3'>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-hat-3" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Full Name"
                        type="text"
                        name='full_name'
                        innerRef={register}
                        invalid={(errors && errors.full_name) && true}
                    />
                </InputGroup>
                {errorRenderer(errors, 'full_name')}
            </FormGroup>
            <FormGroup className='mb-3'>
                <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-email-83" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        name="email"
                        innerRef={register}
                        invalid={(errors && errors.email) && true}
                    />
                </InputGroup>
                {errorRenderer(errors, 'email')}
            </FormGroup>
            <FormGroup className='mb-3'>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={async () => await trigger('password')}
                        name='password'
                        innerRef={register}
                        invalid={(errors && errors.password) && true}
                    />
                </InputGroup>
                {errorRenderer(errors, 'password')}
            </FormGroup>
            <FormGroup className='mb-3'>
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Confirm your password"
                        type="password"
                        autoComplete="confirm-password"
                        name='confirm_password'
                        innerRef={register}
                        invalid={(errors && errors.confirm_password) && true}
                    />
                </InputGroup>
                {errorRenderer(errors, 'confirm_password')}
            </FormGroup>
            <Row className="my-2">
                <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                            name="policy"
                            className="custom-control-input"
                            id="customCheckRegister"
                            ref={register}
                            type="checkbox"
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customCheckRegister"
                        >
                            <span className="text-muted">
                                I agree with the{" "}
                                <a target="_blank" href="https://wfhgo.com/privacy-policy">
                                    Privacy Policy
                                </a>
                            </span>
                        </label>
                    </div>
                    {errorRenderer(errors, 'policy')}
                </Col>
            </Row>
            <div className="text-center">
                <Button disabled={signUpAuth.pending} className="mt-4 w-50" color="primary" type="submit">
                    {signUpAuth.pending ? <Spinner style={{ height: '1rem', width: '1rem' }} /> : "Create account"}
                </Button>
            </div>
        </Form >
    )
}

export default SignUpForm