import firebase from "firebase";
import { startImageUpload, imageUploadSuccess, imageUploadFailure } from "../../actions/Tasks/taskListActions";


class FirebaseImageUploadAdapter {
  constructor( loader, taskId, dispatchRef ) {
      // CKEditor 5's FileLoader instance.
      this.loader = loader;
      // Id of the task to store
      this.taskId = taskId;
      this.dispatchRef = dispatchRef;
  }


  // Starts the upload process.
  upload() {
      return new Promise( ( resolve, reject ) => {
        this.dispatchRef.current(startImageUpload());
        this.uploadToFirebase(resolve, reject);
      });
  }

  // Aborts the upload process.
  abort() {}
  async uploadToFirebase(resolve, reject) {
    var storageRef = firebase.storage().ref();
    const data = await this.loader.file;
    
    const name = Math.floor(Math.random() * 10 ** 5) + data.name;
    var mountainImagesRef = storageRef.child(
      `task-images/${this.taskId}/${name}`
    );

    const uploadRequest =  mountainImagesRef
      .put(data, {
        contentType: data.type,
      });

    uploadRequest.on('state_changed', (snapshot) => {
      // Get the percentage of the upload that is complete
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      this.loader.uploadedPercent = progress;
      this.loader.status = snapshot.state;
    });

    uploadRequest.then((snapshot) => {
        if (!snapshot) {
          this.dispatchRef.current(imageUploadFailure());
          reject('File could not be uploaded');
        }
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            default: downloadURL
          })
          this.dispatchRef.current(imageUploadSuccess());
        }).catch(() => {
          this.dispatchRef.current(imageUploadFailure());
          reject('File could not be uploaded')
        });
      }).catch((err) => {
        this.dispatchRef.current(imageUploadFailure());
        reject('File could not be uploaded')
      })
  }
}

export function FirebaseImageUploadPlugin( editor, taskId, dispatchRef ) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
      return new FirebaseImageUploadAdapter( loader, taskId, dispatchRef );
  };
}