import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
} from "reactstrap";
import TeamInviteForm from './form';
import { useHistory } from 'react-router-dom';



export default function TeamInvite() {

    const history = useHistory()

    return (
        <>
            <Container className="pb-8" fluid >
                <Row id="teamInviteForm" tabIndex={0}>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="12" className='m-0 p-0'>
                                        <div className='d-flex align-items-center'>
                                            <h3 className="mb-0 ml-4">Send Invite to Teams</h3>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <TeamInviteForm />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

